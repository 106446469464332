import React, { useState, useEffect } from "react"
import Loader from "../../Loader/loader.js"
import { Row, Col } from "reactstrap"
import axios from "axios"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../../helpers/Utils"
import Paginations from "../../Pagination"
import MultipleChoice from "../../Filter/MultipleChoice"
import DateFilter from "../../Filter/DateFilter"
import InputFilter from "../../Filter/SearchFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import StaffModal from "../Staff/staffmodal.js"
import CreateBranch from "./createBranch.js"
import BranchList from "./branch.js"

const AllBranches = props => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [users, setUsers] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [allRole, setAllRole] = useState([])
  const [name_filter, setname_filter] = useState(false)
  const [email_filter, setemail_filter] = useState(false)
  const [contact_filter, setcontact_filter] = useState(false)
  const [role_filter, setrole_filter] = useState(false)
  const [leadholder_filter, setleadholder_filter] = useState(false)
  const [status2_filter, setstatus2_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [sort, setSort] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [sort3, setSort3] = useState(false)
  const [sort4, setSort4] = useState(false)
  const [branch, setBranch] = useState("")
  const [searchedCount, setSearchedCount] = useState("")
  const [totalUser, setTotalUser] = useState("")
  const [branch_limit, setBranch_limit] = useState("")

  useEffect(() => {
    const storedData = localStorage.getItem("DashboardData")
    if (storedData) {
      setBranch_limit(JSON.parse(storedData))
    }
  }, [])

  const getAllUsers = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUsers(res.data.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const getAllBranch = () => {
    setAllUsers([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch`,
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(res => {
        setAllUsers(res.data.data)
      })
      .catch(error => {})
  }

  useEffect(() => {
    getAllBranch()
    getAllUsers()
  }, [])

  return (
    <>
      {modal_standard ? (
        <CreateBranch
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          getAllBranch={getAllBranch}
        />
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">
              Branches{" "}
              <span className="text-warning">
                {"  "}
                (Branch Limit {branch_limit?.branch_limit} )
              </span>
            </h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              <button
                className="btn btn-success"
                onClick={() => {
                  setmodal_standard(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Results
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      {/* <div className="card">
        <div className="card-body"> */}
      <div
        className="lead_table table table-sm table-bordered table-responsive"
        // style={{ overflowX: "scroll" }}
      >
        <table style={{ width: "100%", background: "#fff" }}>
          <tbody>
            <tr className="static-header">
              <th>#</th>
              <th>Branch Name</th>
              <th>Branch Address</th>
              <th>Action</th>
            </tr>
            {allUsers &&
              allUsers.map((item, obj) => (
                <BranchList
                  key={obj}
                  data={item}
                  i={obj}
                  counter={counter}
                  getAllUsers={getAllUsers}
                  getAllBranch={getAllBranch}
                />
              ))}
          </tbody>
        </table>
        {error ? (
          <span className="text-danger mt-3 d-table m-auto" role="alert">
            {error}
          </span>
        ) : (
          ""
        )}
      </div>
      {/* </div>
      </div> */}
    </>
  )
}
export default AllBranches
