import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  FormGroup,
  Input,
  Nav,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../helpers/Utils"
import { Link, useLocation, useHistory } from "react-router-dom"
import axios from "axios"
import Paginations from "../Pagination"
import Loader from "../Loader/loader.js"
import ProformaList from "./proformaList"
import ProformaModal from "./proformaModal"
import classnames from "classnames"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import MultipleChoice from "../Filter/MultipleChoice"
import InputFilter from "../Filter/SearchFilter"
import DateFilter from "../Filter/DateFilter"

const ProformaTable = () => {
  const history = useHistory()
  const [modal_proforma2, setmodal_proforma2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [id_filter, setid_filter] = useState(false)
  const [name_filter, setname_filter] = useState(false)
  const [status7_filter, setstatus7_filter] = useState(false)
  const [status8_filter, setstatus8_filter] = useState(false)
  const [leadholder_filter, setleadholder_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [sort, setSort] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [sort3, setSort3] = useState(false)
  const [sort4, setSort4] = useState(false)
  const [sort5, setSort5] = useState(false)
  const [sort6, setSort6] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [searchedCount, setSearchedCount] = useState("")
  const [proforma, setProforma] = useState([])
  const [error, setError] = useState("")
  const [counter, setCounter] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [allData, setAllData] = useState([])
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)
  const [confirm_alert3, setconfirm_alert3] = useState(false)
  const [blur, setBlur] = useState(false)
  const [customActiveTab, setCustomActiveTab] = useState(1)
  const [type, setType] = useState("proforma")
  const [orderType, setOrderType] = useState("proforma,proforma_service")
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  let url1 = query.get("status")
  let url2 = query.get("payment_status")

  const getAllProforma = e => {
    setLoader(<Loader />)
    setProforma([])
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/order?type=${e}&status=${url1 !== null ? url1 : ""}&payment_status=${
      url2 !== null ? url2 : ""
    }&per_page=${
      localStorage.getItem("PerPage") !== null
        ? localStorage.getItem("PerPage")
        : ""
    }&${branchQuery}`
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setProforma(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
    if (tab == 1) {
      getAllProforma("proforma,proforma_service")
      setOrderType("proforma,proforma_service")
    }
    if (tab == 2) {
      getAllProforma("proforma")
      setOrderType("proforma")
    }
    if (tab == 3) {
      getAllProforma("proforma_service")
      setOrderType("proforma_service")
    }
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "id") {
      localStorage.setItem("leadID", e)
    }
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "status7") {
      localStorage.setItem("OrderStatus", e)
    }
    if (type == "status8") {
      localStorage.setItem("OrderPstatus", e)
    }
    if (type == "leadholder") {
      localStorage.setItem("leadHolder", e)
    }
    if (type == "date") {
      setFilterDate(e)
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e)
    }
    setSearchedCount("")
    setProforma([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order?type=${orderType}&id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
         &orderTakenBy=${
           localStorage.getItem("leadHolder") !== null
             ? localStorage.getItem("leadHolder")
             : ""
         }
         &status=${
           localStorage.getItem("OrderStatus") !== null
             ? localStorage.getItem("OrderStatus")
             : ""
         }
         &payment_status=${
           localStorage.getItem("OrderPstatus") !== null
             ? localStorage.getItem("OrderPstatus")
             : ""
         } 
         &customer_name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &from_date=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &to_date=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setProforma(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setid_filter(false)
          setname_filter(false)
          setstatus7_filter(false)
          setstatus8_filter(false)
          setleadholder_filter(false)
          setdate_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setProforma([])
    setCounter("")
    setAllPages([])
    setLoader(<Loader />)
    axios
      .get(
        `${e}&type=${orderType}&id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
            &orderTakenBy=${
              localStorage.getItem("leadHolder") !== null
                ? localStorage.getItem("leadHolder")
                : ""
            }
            &status=${
              localStorage.getItem("OrderStatus") !== null
                ? localStorage.getItem("OrderStatus")
                : ""
            }
            &payment_status=${
              localStorage.getItem("OrderPstatus") !== null
                ? localStorage.getItem("OrderPstatus")
                : ""
            } 
            &customer_name=${
              localStorage.getItem("leadName") !== null
                ? localStorage.getItem("leadName")
                : ""
            }
            &from_date=${
              getFilterDate() && getFilterDate().from !== null
                ? getFilterDate().from
                : ""
            }
            &to_date=${
              getFilterDate() && getFilterDate().to !== null
                ? getFilterDate().to
                : ""
            }
            &sort=${
              localStorage.getItem("leadSort") !== null
                ? localStorage.getItem("leadSort")
                : ""
            }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setProforma(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const leadDisplay = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/proforma-counts`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setLoader("")
        setAllData(res.data.data)
      })
  }

  const getAllUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setAllUsers(res.data.data.data)
        } else {
        }
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const deleteLeads = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-order-deleted`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllProforma(orderType)
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const cancelOrders = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/cancel-order`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllProforma(orderType)
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const deliverOrders = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order-delivered`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllProforma(orderType)
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const exportLead = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/order-export`,
        {
          ids: leadsSelectedIds,
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  useEffect(() => {
    history.listen(location => {
      if (history.location.search !== "") {
        getAllProforma(location.search)
      }
    })
    getAllProforma("proforma,proforma_service")
    getAllUsers()
  }, [])

  return (
    <>
      {modal_proforma2 ? (
        <ProformaModal
          modal_proforma2={modal_proforma2}
          setmodal_proforma2={setmodal_proforma2}
          type={type}
          getAllProforma={getAllProforma}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title="Do you want to cancel selected orders?"
          warning
          showCancel
          confirmButtonText="Yes, do it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert2(false)
            setsuccess_dlg(true)
            cancelOrders()
            setdynamic_title("Deleted")
            setdynamic_description("Selected orders has been cancelled.")
          }}
          onCancel={() => setconfirm_alert2(false)}
        >
          {/* <span className="text-danger">You won't be able to revert this!</span> */}
        </SweetAlert>
      ) : null}
      {confirm_alert3 ? (
        <SweetAlert
          title="Do you want to mark selected orders as delivered?"
          warning
          showCancel
          confirmButtonText="Yes, do it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert3(false)
            setsuccess_dlg(true)
            deliverOrders()
            setdynamic_title("Deleted")
            setdynamic_description("Selected orders has been marked delivered.")
          }}
          onCancel={() => setconfirm_alert3(false)}
        >
          {/* <span className="text-danger">You won't be able to revert this!</span> */}
        </SweetAlert>
      ) : null}
      {modal3 ? (
        <Card style={{ marginTop: "25px" }}>
          <CardBody>
            <Row>
              <Col>
                <Link to="/proformas?status=">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Total
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.all_order}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/proformas?status=pending">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Pending
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.pending}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/proformas?status=cancelled">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Cancelled
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.cancelled}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/proformas?payment_status=paid">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Paid
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.paid}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/proformas?payment_status=unpaid">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Unpaid
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.unpaid}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/proformas?payment_status=p_paid">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Partially Paid
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.p_paid}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={3}>
            <h6 className="page-title">
              {getCurrentUser()?.crm_proforma_title
                ? getCurrentUser()?.crm_proforma_title
                : "Proformas"}
            </h6>
          </Col>
          <Col md={9}>
            <h6 className="page-title float-end">
              {blur ? (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    {getPermission().proforma.proforma.delete.is_checked ===
                    "yes" ? (
                      <li>
                        <span
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                          onClick={() => setconfirm_alert(true)}
                        >
                          Batch Delete
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => setconfirm_alert2(true)}
                      >
                        Cancel{" "}
                        {getCurrentUser()?.crm_proforma_title
                          ? getCurrentUser()?.crm_proforma_title
                          : "Proforma"}
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => setconfirm_alert3(true)}
                      >
                        {getCurrentUser()?.crm_proforma_title
                          ? getCurrentUser()?.crm_proforma_title
                          : "Proforma"}{" "}
                        Delivered
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu"></ul>
                </div>
              )}
              {modal3 ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    setModal3(false)
                  }}
                >
                  {getCurrentUser()?.crm_proforma_title
                    ? getCurrentUser()?.crm_proforma_title
                    : "Proformas"}{" "}
                  Counter
                  <i className="fa fa-arrow-up ms-2"></i>
                </button>
              ) : (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    setModal3(true), leadDisplay()
                  }}
                >
                  {getCurrentUser()?.crm_proforma_title
                    ? getCurrentUser()?.crm_proforma_title
                    : "Proformas"}{" "}
                  Counter
                  <i className="fa fa-arrow-down ms-2"></i>
                </button>
              )}
              <button
                className="btn btn-primary submit__button me-2"
                onClick={() => {
                  exportLead()
                }}
              >
                Export{" "}
                {getCurrentUser()?.crm_proforma_title
                  ? getCurrentUser()?.crm_proforma_title
                  : "Proformas"}
              </button>
              {getCurrentUser().crmpro_permission == 1 ||
              getCurrentUser().crmpronew_permission == 1 ? (
                getPermission().proforma.proforma.create.is_checked ===
                "yes" ? (
                  <>
                    {getCurrentUser().crmpro_services_enabled == 1 ? (
                      <button
                        className="btn btn-success me-2"
                        type="button"
                        onClick={() => {
                          setmodal_proforma2(true), setType("proforma_service")
                        }}
                      >
                        Create Service Line{" "}
                        {getCurrentUser()?.crm_proforma_title
                          ? getCurrentUser()?.crm_proforma_title
                          : "Proforma"}
                      </button>
                    ) : (
                      ""
                    )}
                    {getCurrentUser().crmpro_products_enabled == 1 ? (
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                          setmodal_proforma2(true), setType("proforma")
                        }}
                      >
                        Create Product List{" "}
                        {getCurrentUser()?.crm_proforma_title
                          ? getCurrentUser()?.crm_proforma_title
                          : "Proforma"}
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </h6>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={6}></Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              <span className="text-white bg-danger text-center">
                {getCurrentUser()?.crm_proforma_title
                  ? getCurrentUser()?.crm_proforma_title
                  : "Proforma"}{" "}
                will be automatically deleted after 30 days of creation.
              </span>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Data
                <span> ({searchedCount})</span>
                <button
                  className="btn btn-success ms-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchedCount(""),
                      getAllProforma(orderType),
                      clearLocal()
                  }}
                >
                  <i className="fa me-2" aria-hidden="true">
                    &#xf021;
                  </i>
                  Reset Filters
                </button>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 1 })}
                  onClick={() => {
                    toggleCustom(1)
                  }}
                >
                  {" "}
                  All{" "}
                  {getCurrentUser()?.crm_proforma_title
                    ? getCurrentUser()?.crm_proforma_title
                    : "Proformas"}
                </NavLink>
              </NavItem>
              {getCurrentUser().crmpro_products_enabled == 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 2 })}
                    onClick={() => {
                      toggleCustom(2)
                    }}
                  >
                    {" "}
                    Product List{" "}
                    {getCurrentUser()?.crm_proforma_title
                      ? getCurrentUser()?.crm_proforma_title
                      : "Proformas"}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {getCurrentUser().crmpro_services_enabled == 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === 3 })}
                    onClick={() => {
                      toggleCustom(3)
                    }}
                  >
                    {" "}
                    Service Line{" "}
                    {getCurrentUser()?.crm_proforma_title
                      ? getCurrentUser()?.crm_proforma_title
                      : "Proformas"}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
            </Nav>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Proforma ID
                      {id_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setid_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadID") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort5 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(967%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort5(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(967%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort6 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort5(true),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(967%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="first-filter">
                        <InputFilter
                          id_filter={id_filter}
                          setid_filter={setid_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Name
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Total price</th>
                  {/* <th>Expiry date</th> */}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Status
                      {status7_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setstatus7_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("OrderStatus") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setstatus7_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setstatus7_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        status7_filter={status7_filter}
                        setstatus7_filter={setstatus7_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Payment Status
                      {status8_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setstatus8_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("OrderPstatus") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setstatus8_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setstatus8_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        status8_filter={status8_filter}
                        setstatus8_filter={setstatus8_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Branch</th>
                  <th>Proforma Invoice</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Taken by
                      {leadholder_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setleadholder_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadHolder") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setleadholder_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadholder_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        leadholder_filter={leadholder_filter}
                        setleadholder_filter={setleadholder_filter}
                        data={allUsers}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Created at
                      {date_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdate_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {getFilterDate() && getFilterDate().from == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort3 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort4(true),
                                setSort3(false),
                                setSort(false),
                                setSort2(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("created_at,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort4(true),
                                setSort3(false),
                                setSort(false),
                                setSort2(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("created_at,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(869%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort3(true),
                                setSort4(false),
                                setSort(false),
                                setSort2(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("created_at,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort4(true),
                                setSort3(false),
                                setSort(false),
                                setSort2(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("created_at,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(869%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort4 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort4(false),
                                setSort3(true),
                                setSort(false),
                                setSort2(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("created_at,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(869%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <DateFilter
                        date_filter={date_filter}
                        setdate_filter={setdate_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Action</th>
                </tr>
                {proforma &&
                  proforma.map((item, obj) => (
                    <ProformaList
                      key={obj}
                      data={item}
                      i={obj}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      getAllProforma={getAllProforma}
                      counter={counter}
                      leadDisplay={leadDisplay}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllProforma}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default ProformaTable
