import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

import Emailtemplatealert from "../pages/EmailTemplate/email-template-alert"
import Emailtemplatebasic from "../pages/EmailTemplate/email-template-basic"
import Emailtemplatebilling from "../pages/EmailTemplate/email-template-billing"

// Authentication related pages
import Logout from "../pages/Authentication/Logout"
import ResetPasswordPage from "../pages/AuthenticationInner/Reset-password"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import TypiconsIcon from "../pages/Icons/IconTypicons"
import IconIon from "../pages/Icons/IconIon"
import ThemifyIcon from "../pages/Icons/IconThemify"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesInvoice from "../pages/Utility/PagesInvoice"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesGallery from "../pages/Utility/PagesGallery"
import PagesDirectory from "../pages/Utility/PagesDirectory"

// Custom Pages
import OccupiedLocations from "../pages/OccupiedLocations"
import AllLeads from "../pages/Lead"
import RequestLeads from "../pages/DeadLeads/Request"
import AllDeadLeads from "../pages/DeadLeads/Dead"
import CompanyPoolLeads from "../pages/Lead/CompanyPool"
import AllStatsLeads from "../pages/Lead/AllStats"
import MyLeads from "../pages/Lead/MyLeads"
import DeletedLeads from "../pages/Lead/DeletedLeads"
import RepeatedLeads from "../pages/Lead/ReapeatedLeads"
import DuplicatedLeads from "../pages/Lead/DuplicatedLeads"
import TransferLeads from "../pages/Lead/TranferRequest"
import WinBackLeads from "../pages/Lead/WinbackPool"
import Pricingtable from "../pages/plans"
import Staff from "../pages/Admin"
import Settings from "../pages/Admin/settings"
import Workflow from "../pages/workflow"
import KlikDrive from "../pages/klik-drive"
import NewUser from "../pages/Admin/new-user"
import StaffNewUser from "../pages/Admin/staff-new-user"
import Role from "../pages/Role"
import Messages from "../pages/CreateMessage"
import Campaigns from "../pages/Campaign"
import Dead from "../pages/Dead"
import FollowupStatus from "../pages/Followup-Status"
import Scheme from "../pages/Scheme"
import Mstatus from "../pages/Mature-Status"
import Loc from "../pages/Location"
import LoginLogs from "../pages/Authentication/LoginLogs"
import LdTag from "../pages/LeadTag"
import LdField from "../pages/LeadField"
import LdSource from "../pages/LeadSource"
import LdLabel from "../pages/LeadLabel"
import GlobaliP from "../pages/GlobalIP"
import GlobalSecurity from "../pages/Global-Security"
import Reminder from "../pages/Reminder"
import Fetch from "../pages/Fetch-Email"
import FetchAPI from "../pages/Fetch-Api"
import FollowupToday from "../pages/Followups/Today"
import FollowupFuture from "../pages/Followups/Future"
import ReportToday from "../pages/Daily-report/Today"
import ReportPast from "../pages/Daily-report/Past"
import ActivityLogs from "../pages/Daily-report/ActivityLog"
import CusLeads from "../pages/Customers"
import Orders from "../pages/Order"
import Ledgers from "../pages/Ledger"
import Proformas from "../pages/Proforma"
import Complaints from "../pages/Complaint"
import CustomerSchemes from "../pages/Customer-scheme"
import Offers from "../pages/Offer"
import Notifications from "../pages/Notification"
import Products from "../pages/Products/allProducts"
import ProductLine from "../pages/Products/productLine"
import ProductFields from "../pages/Products/productFields"
import AllCategory from "../pages/Categories/All-categories"
import SubCategory from "../pages/Categories/Sub-categories"
import AllPacking from "../pages/Categories/All-packing"
import PrivacyPolicy from "../pages/Privacy-policy/privacy"
import TermsConditions from "../pages/Privacy-policy/terms-condition"
import ReturnRefundPolicyPage from "../pages/Privacy-policy/refund-policy"
import AccountantPage from "../pages/Accountant"
import EmailPage from "../pages/Admin/emailSmtp"
import WhatsappAPI from "../pages/Admin/whatsappAPI"
import ProformaSetting from "../pages/Admin/proformaSetting"
import WebhookApikey from "../pages/Admin/webhookApikey"
import hostDomain from "../pages/Admin/hostDomain"
import Facebook from "../components/Admin/profileComps/facebook"
import BranchesAll from "../pages/Admin/Branchesall"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //calendar
  { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-compose", component: EmailCompose },

  // Email Template
  { path: "/email-template-alert", component: Emailtemplatealert },
  { path: "/email-template-basic", component: Emailtemplatebasic },
  { path: "/email-template-billing", component: Emailtemplatebilling },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },

  // Icons
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/icons-ion", component: IconIon },
  { path: "/icons-themify", component: ThemifyIcon },
  { path: "/icons-typicons", component: TypiconsIcon },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-invoice", component: PagesInvoice },
  { path: "/pages-directory", component: PagesDirectory },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },
  { path: "/pages-gallery", component: PagesGallery },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  // Custom
  { path: "/occupied-locations", component: OccupiedLocations },
  { path: "/leads", component: AllLeads },
  { path: "/statistics", component: AllStatsLeads },
  { path: "/dead-requests", component: RequestLeads },
  { path: "/deleted-leads", component: DeletedLeads },
  { path: "/dead-leads", component: AllDeadLeads },
  { path: "/company-leads", component: CompanyPoolLeads },
  { path: "/my-leads", component: MyLeads },
  { path: "/repeated-leads", component: RepeatedLeads },
  { path: "/duplicated-leads", component: DuplicatedLeads },
  { path: "/transfer-request", component: TransferLeads },
  { path: "/winback-leads", component: WinBackLeads },
  { path: "/plans", component: Pricingtable },
  { path: "/settings", component: Settings },
  { path: "/workflows", component: Workflow },
  { path: "/staff", component: Staff },
  { path: "/new-user", component: NewUser },
  { path: "/staff-new-user", component: StaffNewUser },
  { path: "/role", component: Role },
  { path: "/messages", component: Messages },
  { path: "/campaigns", component: Campaigns },
  { path: "/dead", component: Dead },
  { path: "/followup-status", component: FollowupStatus },
  { path: "/scheme", component: Scheme },
  { path: "/mature-status", component: Mstatus },
  { path: "/location", component: Loc },
  { path: "/lead-tag", component: LdTag },
  { path: "/lead-fields", component: LdField },
  { path: "/login-logs", component: LoginLogs },
  { path: "/lead-source", component: LdSource },
  { path: "/lead-label", component: LdLabel },
  { path: "/klik-drive", component: KlikDrive },
  { path: "/global-ip", component: GlobaliP },
  { path: "/global-security", component: GlobalSecurity },
  { path: "/reminder", component: Reminder },
  { path: "/fetch-from-email", component: Fetch },
  { path: "/fetch-from-api", component: FetchAPI },
  { path: "/followups-today", component: FollowupToday },
  { path: "/followups-future", component: FollowupFuture },
  { path: "/today-report", component: ReportToday },
  { path: "/past-report", component: ReportPast },
  { path: "/activity-logs", component: ActivityLogs },
  { path: "/customers", component: CusLeads },
  { path: "/orders", component: Orders },
  { path: "/ledgers", component: Ledgers },
  { path: "/proformas", component: Proformas },
  { path: "/complaints", component: Complaints },
  { path: "/schemes", component: CustomerSchemes },
  { path: "/offers", component: Offers },
  { path: "/notifications", component: Notifications },
  { path: "/products", component: Products },
  { path: "/product-line", component: ProductLine },
  { path: "/product-fields", component: ProductFields },
  { path: "/all-packing", component: AllPacking },
  { path: "/sub-category", component: SubCategory },
  { path: "/all-category", component: AllCategory },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-conditions", component: TermsConditions },
  { path: "/return-policy", component: ReturnRefundPolicyPage },
  { path: "/accounts", component: AccountantPage },
  { path: "/connect-email", component: EmailPage },
  { path: "/connect-whatsapp", component: WhatsappAPI },
  { path: "/proforma-settings", component: ProformaSetting },
  { path: "/webhook-keys", component: WebhookApikey },
  { path: "/host-domain", component: hostDomain },
  { path: "/connect-meta", component: Facebook },
  { path: "/branches", component: BranchesAll },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login1 },
  { path: "/forgot-password", component: Recoverpw },
  { path: "/reset-password", component: ResetPasswordPage },
  { path: "/register", component: Register1 },
  { path: "/404", component: Pages404 },
  { path: "/500", component: Pages500 },
  { path: "/plans", component: Pricingtable },

  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // Authentication Inner
  // { path: "/pages-login", component: Login1 },
  // { path: "/pages-login-2", component: Login2 },
  // { path: "/pages-register", component: Register1 },
  // { path: "/pages-register-2", component: Register2 },
  // { path: "/page-recoverpw", component: Recoverpw },
  // { path: "/page-recoverpw-2", component: Recoverpw2 },
  // { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  // { path: "/auth-lock-screen", component: LockScreen },
  // { path: "/auth-lock-screen-2", component: LockScreen2 },
  // { path: "/page-confirm-mail", component: ConfirmMail },
  // { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  // { path: "/auth-email-verification", component: EmailVerification },
  // { path: "/auth-email-verification-2", component: EmailVerification2 },
  // { path: "/auth-two-step-verification", component: TwostepVerification },
  // { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
