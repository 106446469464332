import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import Loader from "../Loader/loader.js"
import Select from "react-select"
import CorderModal from "../Customer-Profile/C-order/CorderModal"
import ServiceLineOrder from "../Customer-Profile/C-order/serviceLineOrder"

export default function OrderModal(props) {
  const [modal_standard, setmodal_standard] = useState(false)
  const [service_order, setservice_order] = useState(false)
  const [loader, setLoader] = useState("")
  const [error, setError] = useState("")
  const [error2, setError2] = useState("")
  const [customer, setCustomer] = useState([])
  const [customerID, setCustomerID] = useState("")
  const [branches, setBranches] = useState([])
  const [error3, setError3] = useState("")
  const [selectedBranches, setSelectedBranches] = useState()
  const [newmodal_element, setnewModal_element] = useState(false)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  const getCustomers = () => {
    setLoader(<Loader />)
    setCustomer([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/customers-min-fields`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCustomer(res.data.data)
          setLoader("")
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const options = []

  if (customer !== undefined) {
    customer.map(item => {
      options.push({
        value: item.id,
        label: [item.name, " ", `(${item.number})`, " ", item.firm_name],
      })
    })
  }

  const submitOrder = () => {
    if (customerID == "") {
      setError2("Please select a customer.")
    } else {
      setError2("")
      if (props.type == "order") {
        setmodal_standard(true)
      } else {
        setservice_order(true)
      }
    }
  }
  const showAllBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token || ""}`,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          const branchOptions = res.data.data.map(branch => ({
            label: branch.name,
            value: branch.id,
          }))
          setBranches(branchOptions)
        } else {
          setError3(res.data.message)
        }
      })
  }

  const handleSelectChange = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setSelectedBranches(selectedValues)
  }
  const handleSelectChanges = e => {
    const selectedValue = parseInt(e.target.selectedOptions[0]?.value, 10)
    setSelectedBranches(selectedValue)
  }

  useEffect(() => {
    getCustomers()
    showAllBranch()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_order(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      {modal_standard ? (
        <CorderModal
          modal_standard={modal_standard}
          id={customerID}
          setmodal_standard={setmodal_standard}
          setmodal_order={props.setmodal_order}
          getAllOrders={props.getOrder}
          branch_id={selectedBranches}
        />
      ) : null}
      {service_order ? (
        <ServiceLineOrder
          service_order={service_order}
          id={customerID}
          setservice_order={setservice_order}
          setmodal_order={props.setmodal_order}
          getAllOrders={props.getOrder}
          branch_id={selectedBranches}
        />
      ) : null}
      <Modal isOpen={props.modal_order} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={() => {
            submitOrder()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Select Customer
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_order(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {loader != "" ? (
              loader
            ) : (
              <>
                {getCurrentUser().enable_branch === 1 &&
                getCurrentUser().role_name === "Administrator" ? (
                  <>
                    {" "}
                    <div className="mb-3">
                      <label htmlFor="branch-select" className="form-label">
                        Branch
                      </label>
                      <AvField
                        type="select"
                        name="branch"
                        id="branch-select"
                        onChange={handleSelectChanges}
                      >
                        <option>Select Branch</option>
                        {branches.map((branch, index) => (
                          <option key={index} value={branch.value}>
                            {branch.label}
                          </option>
                        ))}
                      </AvField>
                    </div>
                  </>
                ) : null}
                <div className="mb-3 required">
                  <label>Select Customer</label>
                  <Select
                    id="customer"
                    name="customer"
                    options={options}
                    onChange={e => setCustomerID(e.value)}
                  />
                </div>
              </>
            )}
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : null}
            {error2 ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error2}
              </span>
            ) : null}
          </div>
          <div className="modal-footer">
            <div className="text-center">
              <button
                className="btn btn-primary"
                type="submit"
                // onClick={() => setmodal_standard(true)}
              >
                Submit
              </button>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
