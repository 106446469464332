import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import GlobalSecurityList from "./Global-Security-List"
import GlobalSecurityModal from "./Global-Security-Modal"
import MultipleChoice from "../../Filter/MultipleChoice"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"

const GlobalSecurityStaff = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [allGlobalSecurity, setAllGlobalSecurity] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [day_filter, setday_filter] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [blur, setBlur] = useState(false)
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [branches, setBranches] = useState([])
  const [error2, setError2] = useState("")
  const [selectedBranches, setSelectedBranches] = useState()
  const [newmodal_element, setnewModal_element] = useState(false)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )
  console.log("showBranch", branch)
  const getAllGlobalSecurity = () => {
    setLoader(<Loader />)
    setAllGlobalSecurity([])
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/global/security?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }?&${branchQuery}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setAllGlobalSecurity(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setAllGlobalSecurity([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}&day=${
          localStorage.getItem("leadDay") !== null
            ? localStorage.getItem("leadDay")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllGlobalSecurity(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "day") {
      localStorage.setItem("leadDay", e)
    }
    setSearchedCount("")
    setAllGlobalSecurity([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/global/security?day=${
          localStorage.getItem("leadDay") !== null
            ? localStorage.getItem("leadDay")
            : ""
        }&per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllGlobalSecurity(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setday_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const exportLead = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/globalsecurity-export`,
        {
          ids: leadsSelectedIds,
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-globalsecurity-export-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const deleteLeads = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-security-deleted`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getAllGlobalSecurity()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }
  const showAllBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token || ""}`,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          const branchOptions = res.data.data.map(branch => ({
            label: branch.name,
            value: branch.id,
          }))
          setBranches(branchOptions)
        } else {
          setError2(res.data.message)
        }
      })
  }

  const handleSelectChange = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setSelectedBranches(selectedValues)
  }
  const handleSubmit = e => {
    e.preventDefault()

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/global/copy-security`,
        {
          ids: leadsSelectedIds,
          branch_id: selectedBranches,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    getAllGlobalSecurity()
    showAllBranch()
  }, [])

  return (
    <>
      <GlobalSecurityModal
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
        getAllGlobalSecurity={getAllGlobalSecurity}
      />
      <Modal isOpen={newmodal_element} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={handleSubmit}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Copy Globar Security
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => setnewModal_element(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="my-2">
              <label htmlFor="branch-select" className="form-label">
                Branch
              </label>
              <Select
                isMulti
                options={branches}
                onChange={handleSelectChange}
                placeholder="Select Branches"
              />
            </div>

            <div className="modal-footer">
              <div className="text-end">
                {/* {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                            onClick={() => props.setmodal_standard(false)}
                           >
                              {props.globalSecurity !== undefined
                                 ? "Submit"
                                 : "Create"}
                           </button>
                        )} */}
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => setnewModal_element(false)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>

      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Global Securities</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {blur ? (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setnewModal_element(true)
                        }}
                      >
                        Copy
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-primary" disabled>
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    disabled
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert(true)
                        }}
                      >
                        Batch delete
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              {getPermission() &&
              getPermission().lead_export.lead_export.view.is_checked ===
                "yes" ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    exportLead()
                  }}
                >
                  Export
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_standard(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Results
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Day
                      {day_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setday_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadDay") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setday_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setday_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        day_filter={day_filter}
                        setday_filter={setday_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Branch</th>
                  <th>Action</th>
                </tr>
                {allGlobalSecurity &&
                  allGlobalSecurity.map((item, obj) => (
                    <GlobalSecurityList
                      key={obj}
                      data={item}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      i={obj}
                      branches={branches}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllGlobalSecurity}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default GlobalSecurityStaff
