import React, { useState, useEffect } from "react"
import Loader from "../../Loader/loader.js"
import { Row, Col, Card, CardBody } from "reactstrap"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../../helpers/Utils"
import { Link, useLocation, useHistory } from "react-router-dom"
import Paginations from "../../Pagination"
import DeletedList from "./deletedList"
import LeadModal from "../leadmodal"
import MultipleChoice from "../../Filter/MultipleChoice"
import InputFilter from "../../Filter/SearchFilter"
import DateFilter from "../../Filter/DateFilter"
import LeadsFilter from "../../LeadFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const DeletedLeadsTable = () => {
  const history = useHistory()
  const [modal_standard, setmodal_standard] = useState(false)
  const [id_filter, setid_filter] = useState(false)
  const [name_filter, setname_filter] = useState(false)
  const [contact_filter, setcontact_filter] = useState(false)
  const [leadsource_filter, setleadsource_filter] = useState(false)
  const [tag_filter, settag_filter] = useState(false)
  const [label_filter, setlabel_filter] = useState(false)
  const [leadholder_filter, setleadholder_filter] = useState(false)
  const [status_filter, setstatus_filter] = useState(false)
  const [date_filter, setdate_filter] = useState(false)
  const [message_filter, setmessage_filter] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [blur, setBlur] = useState(false)
  const [sort, setSort] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [sort3, setSort3] = useState(false)
  const [sort4, setSort4] = useState(false)
  const [sort5, setSort5] = useState(false)
  const [sort6, setSort6] = useState(false)
  const [allLeads, setAllLeads] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [allData, setAllData] = useState([])
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [counter, setCounter] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_restore, setconfirm_restore] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [total, setTotal] = useState("")
  const [lead_filter, setlead_filter] = useState(false)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  let url1 = query.get("ids")
  let url2 = query.get("type")
  let url3 = query.get("followup_status_id")

  const getAllLeads = id => {
    setLoader(<Loader />)
    setAllLeads([])
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/deleted-leads?ids=${url1 !== null ? url1 : ""}&type=${
      url2 !== null ? url2 : ""
    }&followup_status_id=${url3 !== null ? url3 : ""}&per_page=${
      localStorage.getItem("PerPage") !== null
        ? localStorage.getItem("PerPage")
        : ""
    }&${branchQuery}`
    if (id !== undefined) {
      url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/deleted-leads${id}&per_page=${
        localStorage.getItem("PerPage") !== null
          ? localStorage.getItem("PerPage")
          : ""
      }`
    }
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setTotal(res.data.data.total)
          setAllLeads(res.data.data.data)
          setAllPages(res.data.data)
          setBlur(false)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "id") {
      localStorage.setItem("leadID", e)
    }
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "contact") {
      localStorage.setItem("leadContact", e)
    }
    if (type == "leadsource") {
      localStorage.setItem("leadSource", e)
    }
    if (type == "tag") {
      localStorage.setItem("leadTag", e)
    }
    if (type == "label") {
      localStorage.setItem("leadLabel", e)
    }
    if (type == "leadholder") {
      localStorage.setItem("leadHolder", e)
    }
    if (type == "status") {
      localStorage.setItem("leadStatus", e)
    }
    if (type == "date") {
      setFilterDate(e)
    }
    if (type == "message") {
      localStorage.setItem("leadMessage", e)
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e)
    }
    setSearchedCount("")
    setAllLeads([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/deleted-leads?type=${
          localStorage.getItem("leadStatus") !== null
            ? localStorage.getItem("leadStatus")
            : ""
        }
         &id=${
           localStorage.getItem("leadID") !== null
             ? localStorage.getItem("leadID")
             : ""
         }
         &leadholder_id=${
           localStorage.getItem("leadHolder") !== null
             ? localStorage.getItem("leadHolder")
             : ""
         }
         &contact=${
           localStorage.getItem("leadContact") !== null
             ? localStorage.getItem("leadContact")
             : ""
         } 
         &name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &lead_label_id=${
           localStorage.getItem("leadLabel") !== null
             ? localStorage.getItem("leadLabel")
             : ""
         }
         &tag_id=${
           localStorage.getItem("leadTag") !== null
             ? localStorage.getItem("leadTag")
             : ""
         }
         &lead_source_id=${
           localStorage.getItem("leadSource") !== null
             ? localStorage.getItem("leadSource")
             : ""
         }
         &followup_start=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &followup_to=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &lastmessage=${
           localStorage.getItem("leadMessage") !== null
             ? localStorage.getItem("leadMessage")
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &firm_name=${
           localStorage.getItem("leadFirmName") !== null
             ? localStorage.getItem("leadFirmName")
             : ""
         }
         &no_updation_since=${
           localStorage.getItem("leadUpdateSince") !== null
             ? localStorage.getItem("leadUpdateSince")
             : ""
         }
         &flag=${
           localStorage.getItem("leadFlag") !== null
             ? localStorage.getItem("leadFlag")
             : ""
         }&company_pool=${
          localStorage.getItem("CompanyPool") !== null
            ? localStorage.getItem("CompanyPool")
            : ""
        }&custom_filters=${
          localStorage.getItem("leadfields_filter") !== null
            ? localStorage.getItem("leadfields_filter")
            : ""
        }
            &assigned=${
              localStorage.getItem("leadAssigned") !== null
                ? localStorage.getItem("leadAssigned")
                : ""
            }
         &transfer_request=${
           localStorage.getItem("leadTransfer") !== null
             ? localStorage.getItem("leadTransfer")
             : ""
         }
         &from_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
             : ""
         }
         &to_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
             : ""
         }
            &mature_start_date=${
              localStorage.getItem("leadMatured_at") !== ""
                ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
                : ""
            }
         &mature_end_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllLeads(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setid_filter(false)
          setname_filter(false)
          setcontact_filter(false)
          setleadsource_filter(false)
          settag_filter(false)
          setlabel_filter(false)
          setdate_filter(false)
          setmessage_filter(false)
          setleadholder_filter(false)
          setstatus_filter(false)
          setlead_filter(false)
        } else {
          setError(res.data.message)
          setlead_filter(false)
        }
      })
  }

  const paginate = e => {
    setAllLeads([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}&type=${
          localStorage.getItem("leadStatus") !== null
            ? localStorage.getItem("leadStatus")
            : ""
        }
      &id=${
        localStorage.getItem("leadID") !== null
          ? localStorage.getItem("leadID")
          : ""
      }
      &leadholder_id=${
        localStorage.getItem("leadHolder") !== null
          ? localStorage.getItem("leadHolder")
          : ""
      }
      &contact=${
        localStorage.getItem("leadContact") !== null
          ? localStorage.getItem("leadContact")
          : ""
      } 
      &name=${
        localStorage.getItem("leadName") !== null
          ? localStorage.getItem("leadName")
          : ""
      }
      &lead_label_id=${
        localStorage.getItem("leadLabel") !== null
          ? localStorage.getItem("leadLabel")
          : ""
      }
      &tag_id=${
        localStorage.getItem("leadTag") !== null
          ? localStorage.getItem("leadTag")
          : ""
      }
      &lead_source_id=${
        localStorage.getItem("leadSource") !== null
          ? localStorage.getItem("leadSource")
          : ""
      }
      &followup_start=${
        getFilterDate() && getFilterDate().from !== null
          ? getFilterDate().from
          : ""
      }
      &followup_to=${
        getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
      }
      &lastmessage=${
        localStorage.getItem("leadMessage") !== null
          ? localStorage.getItem("leadMessage")
          : ""
      }
      &sort=${
        localStorage.getItem("leadSort") !== null
          ? localStorage.getItem("leadSort")
          : ""
      }
      &firm_name=${
        localStorage.getItem("leadFirmName") !== null
          ? localStorage.getItem("leadFirmName")
          : ""
      }
      &no_updation_since=${
        localStorage.getItem("leadUpdateSince") !== null
          ? localStorage.getItem("leadUpdateSince")
          : ""
      }
      &flag=${
        localStorage.getItem("leadFlag") !== null
          ? localStorage.getItem("leadFlag")
          : ""
      }
         &assigned=${
           localStorage.getItem("leadAssigned") !== null
             ? localStorage.getItem("leadAssigned")
             : ""
         }
      &transfer_request=${
        localStorage.getItem("leadTransfer") !== null
          ? localStorage.getItem("leadTransfer")
          : ""
      }
      &from_date=${
        localStorage.getItem("leadCreated_at") !== ""
          ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
          : ""
      }
      &to_date=${
        localStorage.getItem("leadCreated_at") !== ""
          ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
          : ""
      }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllLeads(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const restoreLead = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/restore-leads`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          getSearchedLeads()
          setLeadsSelectedIds([])
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const deleteLead = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead-force-deleted`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          getSearchedLeads()
          setLeadsSelectedIds([])
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const leadDisplay = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/deleted-lead-counter`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setAllData(res.data.data)
        setLoader("")
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const clearLocal = () => {
    localStorage.setItem("leadHolder", "")
    localStorage.setItem("leadLabel", "")
    localStorage.setItem("leadStatus", "")
    localStorage.setItem("leadTag", "")
    localStorage.setItem("leadName", "")
    localStorage.setItem("leadContact", "")
    localStorage.setItem("leadSource", "")
    localStorage.setItem("leadAmount", "")
    setFilterDate({
      from: "",
      to: "",
    })
    localStorage.setItem("leadMessage", "")
    localStorage.setItem("followupStatus", "")
    localStorage.setItem("matureStatus", "")
    localStorage.setItem("deadReason", "")
    localStorage.setItem("deadStatus", "")
    localStorage.setItem("latestMessage", "")
    localStorage.setItem("leadRole", "")
    localStorage.setItem("leadEmail", "")
    localStorage.setItem("leadStatus2", "")
    localStorage.setItem("leadDay", "")
    localStorage.setItem("leadStatus3", "")
    localStorage.setItem("leadmaturedby", "")
    localStorage.setItem("ledgerType", "")
    localStorage.setItem("offerType", "")
    localStorage.setItem("PrductGST", "")
    localStorage.setItem("OrderStatus", "")
    localStorage.setItem("OrderPstatus", "")
    localStorage.setItem("leadSort", "")
    localStorage.setItem("leadID", "")
    localStorage.setItem("leadTransfer", "")
    localStorage.setItem("leadFlag", "")
    localStorage.setItem("leadUpdateSince", "")
    localStorage.setItem("leadOrderSince", "")
    localStorage.setItem("leadFirmName", "")
    localStorage.setItem("leadCreated_at", "")
    localStorage.setItem("leadMatured_at", "")
    localStorage.setItem("current_page", "")
    localStorage.setItem("CompanyPool", "")
  }

  useEffect(() => {
    history.listen(location => {
      if (history.location.search !== "") {
        getAllLeads(location.search)
      }
    })
    getAllLeads()
  }, [])

  return (
    <>
      {modal_standard ? (
        <LeadModal
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          getAllLeads={getAllLeads}
        />
      ) : (
        ""
      )}
      {lead_filter ? (
        <LeadsFilter
          lead_filter={lead_filter}
          setlead_filter={setlead_filter}
          getSearchedLeads={getSearchedLeads}
        />
      ) : (
        ""
      )}
      {modal3 ? (
        <Card style={{ marginTop: "25px" }}>
          <CardBody>
            <Row>
              <Col>
                <Link to="/deleted-leads?type=">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          All Leads
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.all_leads}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/deleted-leads?type=new">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          New Leads
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.new}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/deleted-leads?type=dead">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Dead
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.dead}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/deleted-leads?type=dead_request">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Dead Request
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.dead_request}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/deleted-leads?type=matured">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Matured Leads
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.matured}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col>
                <Link to="/deleted-leads?type=followup">
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="">
                        <h5 className="font-size-16 mt-0 text-uppercase text-white-50">
                          FollowUps
                        </h5>
                        <h4 className="fw-medium font-size-18">
                          {allData.followup}{" "}
                        </h4>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
            {loader}
          </CardBody>
        </Card>
      ) : (
        ""
      )}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={2}>
            <h6 className="page-title">
              Deleted Leads
              {total !== "" ? <span> ({total})</span> : ""}
            </h6>
          </Col>
          <Col md={10}>
            <h6 className="page-title float-end">
              {modal3 ? (
                <button
                  className="btn btn-primary submit__button me-2 mb-2"
                  onClick={() => {
                    setModal3(false)
                  }}
                >
                  Leads Counter
                  <i className="fa fa-arrow-up ms-2"></i>
                </button>
              ) : (
                <button
                  className="btn btn-primary submit__button me-2 mb-2"
                  onClick={() => {
                    setModal3(true), leadDisplay()
                  }}
                >
                  Leads Counter
                  <i className="fa fa-arrow-down ms-2"></i>
                </button>
              )}
              <div className="btn-group me-2 mb-2">
                <button type="button" className="btn btn-primary">
                  Sort by
                </button>
                <button
                  type="button"
                  className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-caret-down"></i>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => getSearchedLeads("name,asc", "sort")}
                    >
                      A to Z
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => getSearchedLeads("name,desc", "sort")}
                    >
                      Z to A
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => getSearchedLeads("id,desc", "sort")}
                    >
                      Latest Added
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("updated_at,desc", "sort")
                      }
                    >
                      Latest Updated
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("followup_counter,desc", "sort")
                      }
                    >
                      Most Followups
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("followup_counter,asc", "sort")
                      }
                    >
                      Least Followups
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        getSearchedLeads("flag_date_time,ASC", "sort")
                      }
                    >
                      Sort by Star
                    </span>
                  </li>
                  {localStorage.getItem("ShowImportant") ? (
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          getSearchedLeads("important_date,ASC", "sort")
                        }
                      >
                        Sort by Important Date
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              {blur ? (
                <>
                  <button
                    className="btn btn-primary submit__button me-2 mb-2"
                    onClick={() => {
                      setconfirm_restore(true)
                    }}
                  >
                    Restore Leads
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-primary submit__button me-2 mb-2"
                    disabled
                  >
                    Restore Leads
                  </button>
                  {/* <button
                              className="btn btn-primary submit__button me-2 mb-2"
                              disabled
                           >
                              <i className="fa fa-trash me-2"></i>Empty Bin
                           </button> */}
                </>
              )}
              <button
                className="btn btn-primary submit__button me-2 mb-2"
                onClick={() => {
                  setconfirm_alert(true)
                }}
              >
                <i className="fa fa-trash me-2"></i>
                Empty Bin
              </button>
              <button
                className="btn filter__button me-2 mb-2"
                onClick={() => {
                  setlead_filter(true)
                }}
              >
                <i className="fa fa-filter me-2"></i>
                Other Filter
              </button>
              {/* {getPermission().lead.lead.create.is_checked === "yes" ?
                        <button
                           className="btn btn-primary submit__button mb-2"
                           onClick={() => {
                              setmodal_standard(true)
                           }}
                        >
                           Add New
                        </button>
                        : ""} */}
            </h6>
          </Col>
          <Col md={12}>
            <h6 className="page-title float-end">
              <span className="text-danger fst-italic text-center p-1 me-2">
                Trashed leads will be automatically deleted after 7 days.
              </span>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Leads
                <span> ({searchedCount})</span>
                <button
                  className="btn btn-success ms-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchedCount(""), getAllLeads(), clearLocal()
                  }}
                >
                  <i className="fa me-2" aria-hidden="true">
                    &#xf021;
                  </i>
                  Reset Filters
                </button>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      {/* <Modal
            isOpen={modal3}
            centered={true}
         >
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e) => {
                  getSearchedLeads(e)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Filter Leads
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal3(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        type="select"
                        name="type"
                        label="By Lead"
                     >
                        <option value="" hidden>
                           Select Type
                        </option>
                        <option value="new">New</option>
                        <option value="followup">Followup</option>
                        <option value="matured">Mature</option>
                        <option value="dead_request">Dead Request
                        </option>
                        <option value="dead">Dead</option>

                     </AvField>
                  </div>
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="By Leadholder"
                        type="select"
                        name="lead_holder_id"
                     >
                        <option value="" hidden>Select User</option>
                        {JSON.parse(localStorage.getItem("AllUsers")) &&
                     JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                           <option value={item.id} key={i}>{item.name}</option>
                        ))}

                     </AvField>
                  </div>
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="By Followup Stages"
                        type="select"
                        name="followup_status_id"
                     >
                        <option value="" hidden>Select</option>
                        {allFollowup && allFollowup.map((item, i) => (
                           <option value={item.id} key={i}>{item.name}</option>
                        ))}

                     </AvField>
                  </div>
                  <div className="mb-3">
                     <AvField
                        label="By Lead Name"
                        type="text"
                        name="name"
                        placeholder="Enter name"
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        label="By Email"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        label="By Phone Number"
                        type="tel"
                        name="number"
                        placeholder="Enter number"
                     />
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={() => setModal3(false)}
                           >
                              Submit
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal> */}
      {confirm_restore ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_restore(false)
            setsuccess_dlg(true)
            restoreLead()
            setdynamic_title("Restored")
            setdynamic_description("You file has been restored.")
          }}
          onCancel={() => setconfirm_restore(false)}
        >
          You want to restore selected leads!
        </SweetAlert>
      ) : null}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLead()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">
            {" "}
            You want to delete {blur ? "selected" : "all"} leads from server?
          </span>
        </SweetAlert>
      ) : null}
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered  table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  {/* <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      ID
                      {id_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setid_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadID") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort5 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort5(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort6 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort5(true),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="first-filter">
                        <InputFilter
                          id_filter={id_filter}
                          setid_filter={setid_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </div>
                  </th> */}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Name
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {/*{sort ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort(true),
                                                setSort2(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort2 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(false),
                                                setSort(true),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("name,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(615%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )} */}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Contact
                      {contact_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setcontact_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadContact") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setcontact_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setcontact_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        contact_filter={contact_filter}
                        setcontact_filter={setcontact_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Lead Source
                      {leadsource_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setleadsource_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadSource") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setleadsource_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadsource_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        leadsource_filter={leadsource_filter}
                        setleadsource_filter={setleadsource_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Branch</th>
                  {getCurrentUser() && getCurrentUser().tag == 1 ? (
                    <th>
                      <div
                        className="position-relative"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Tag
                        {tag_filter ? (
                          <>
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => settag_filter(false)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          </>
                        ) : (
                          <>
                            {localStorage.getItem("leadTag") == "" ? (
                              <span
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={() => settag_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            ) : (
                              <span
                                className=""
                                style={{ cursor: "pointer", color: "orange" }}
                                onClick={() => settag_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            )}
                          </>
                        )}
                        <MultipleChoice
                          tag_filter={tag_filter}
                          settag_filter={settag_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </th>
                  ) : (
                    ""
                  )}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Label
                      {label_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setlabel_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadLabel") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setlabel_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setlabel_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        label_filter={label_filter}
                        setlabel_filter={setlabel_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  {getCurrentUser().role_name == "Executive" ? (
                    ""
                  ) : (
                    <th>
                      <div
                        className="position-relative"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Leadholder
                        {leadholder_filter ? (
                          <>
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setleadholder_filter(false)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          </>
                        ) : (
                          <>
                            {localStorage.getItem("leadHolder") == "" ? (
                              <span
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={() => setleadholder_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            ) : (
                              <span
                                className=""
                                style={{ cursor: "pointer", color: "orange" }}
                                onClick={() => setleadholder_filter(true)}
                              >
                                <i className="fa fa-filter ms-2"></i>
                              </span>
                            )}
                          </>
                        )}
                        <MultipleChoice
                          leadholder_filter={leadholder_filter}
                          setleadholder_filter={setleadholder_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </th>
                  )}
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Status
                      {status_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setstatus_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadStatus") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setstatus_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setstatus_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        status_filter={status_filter}
                        setstatus_filter={setstatus_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Followup
                      {date_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setdate_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {getFilterDate() && getFilterDate().from == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setdate_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {/* {sort3 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,asc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,asc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(792%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort3(true),
                                                setSort4(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,asc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,desc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(792%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort4 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort4(false),
                                                setSort3(true),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "followup_counter,desc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(792%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )} */}
                      <DateFilter
                        date_filter={date_filter}
                        setdate_filter={setdate_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Last Message
                      {message_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setmessage_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadMessage") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setmessage_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <InputFilter
                        message_filter={message_filter}
                        setmessage_filter={setmessage_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                </tr>
                {allLeads &&
                  allLeads.map((item, obj) => (
                    <DeletedList
                      key={obj}
                      getAllLeads={getSearchedLeads}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      data={item}
                      i={obj}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getSearchedLeads}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default DeletedLeadsTable
