import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter, useHistory } from "react-router-dom"
import classname from "classnames"
import {
  getCurrentUser,
  getPermission,
  setCurrentUser,
  setFilterDate,
  setFilterDate2,
} from "../../helpers/Utils"
import axios from "axios"
import RoleModal from "../Admin/Role/RoleModal"
import LeadModal from "../Lead/leadmodal"
import LocationModal from "../Admin/Location/LocationModal"
import MigrationModal from "../Migration"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { AvField } from "availity-reactstrap-validation"
import Loader from "../Loader/loader"

const Navbar = props => {
  const [crm, setcrm] = useState(false)
  const [categorie, setcategorie] = useState(false)
  const [leadSetting, setleadSetting] = useState(false)
  const [product, setproduct] = useState(false)
  const [setting, setSetting] = useState(false)
  const [follow, setfollow] = useState(false)
  const [email, setemail] = useState(false)
  const [component, setcomponent] = useState(false)
  const [extra, setextra] = useState(false)
  const [auth, setauth] = useState(false)
  const [admin, setadmin] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal2, setmodal2] = useState(false)
  const [migration_modal, setmigration_modal] = useState(false)
  const [branches, setBranches] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(
    localStorage.getItem("selectedBranch") || null
  )
  const [error2, setError2] = useState("")
  const [branchData, setBranchData] = useState(null)
  const [loader, setLoader] = useState(<Loader />)
  const [counter, setCounter] = useState(
    localStorage.getItem("NavCounters")
      ? JSON.parse(localStorage.getItem("NavCounters"))
      : {}
  )

  const history = useHistory()

  const getAllCounters = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/all-counter?lms_active=${
          getCurrentUser().crm_permission == 0 &&
          getCurrentUser().crmpro_permission == 0
            ? 1
            : 0
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCounter(res.data.data)
        } else {
          setCounter("")
          if (res.data.message === "Unauthenticated.") {
            setCurrentUser("")
            localStorage.setItem("DashboardData", "")
            history.push("/login")
          } else {
          }
        }
      })
      .catch(err => {
        setCurrentUser("")
        history.push("/login")
      })
  }

  const showAllBranch = () => {
    setLoader(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token || ""}`,
          },
        }
      )
      .then(res => {
        setLoader(false)
        if (res.data.status) {
          setBranches(res.data.data)
        } else {
          setError2(res.data.message)
        }
      })
      .catch(err => {
        setError2("Failed to fetch branches.")
      })
  }

  const showBranch = id => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch/${id}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token || ""}`,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          setSelectedBranch(res.data.data)
          localStorage.setItem("selectedBranch", id || "")
          setBranchData(res.data.data)
        } else {
          setError2(res.data.message)
        }
      })
      .catch(() => {
        setError2("Failed to fetch branch data.")
      })
  }

  useEffect(() => {
    // getAllCounters()
    setCounter(
      localStorage.getItem("NavCounters")
        ? JSON.parse(localStorage.getItem("NavCounters"))
        : {}
    )
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const type = params.get("statistics")
    if (type === "1") {
      localStorage.setItem("leadAmount", "")
      setFilterDate({
        from: "",
        to: "",
      })
      setFilterDate2({
        from: "",
        to: "",
      })
      localStorage.setItem("leadMessage", "")
      localStorage.setItem("deadStatus", "")
      localStorage.setItem("latestMessage", "")
      localStorage.setItem("leadRole", "")
      localStorage.setItem("leadEmail", "")
      localStorage.setItem("leadStatus2", "")
      localStorage.setItem("leadDay", "")
      localStorage.setItem("leadStatus3", "")
      localStorage.setItem("leadmaturedby", "")
      localStorage.setItem("ledgerType", "")
      localStorage.setItem("offerType", "")
      localStorage.setItem("PrductGST", "")
      localStorage.setItem("OrderStatus", "")
      localStorage.setItem("OrderPstatus", "")
      localStorage.setItem("leadSort", "")
      localStorage.setItem("leadID", "")
      localStorage.setItem("leadMatured_at", "")
      localStorage.setItem(
        "leadAssigned_at",
        JSON.stringify({
          startDate: "",
          endDate: "",
        })
      )
      localStorage.setItem("current_page", "")
      localStorage.setItem("leadAction", "")
      localStorage.setItem("leadRestored", "")
      localStorage.setItem("logType", "")
      localStorage.setItem("leadfields_filter", JSON.stringify({ data: "" }))
    } else {
      localStorage.setItem("leadHolder", "")
      localStorage.setItem("leadLabel", "")
      localStorage.setItem("leadStatus", "")
      localStorage.setItem("leadTag", "")
      localStorage.setItem("leadName", "")
      localStorage.setItem("leadContact", "")
      localStorage.setItem("leadSource", "")
      localStorage.setItem("leadAmount", "")
      setFilterDate({
        from: "",
        to: "",
      })
      setFilterDate2({
        from: "",
        to: "",
      })
      localStorage.setItem("leadMessage", "")
      localStorage.setItem("followupStatus", "")
      localStorage.setItem("matureStatus", "")
      localStorage.setItem("deadReason", "")
      localStorage.setItem("deadStatus", "")
      localStorage.setItem("latestMessage", "")
      localStorage.setItem("leadRole", "")
      localStorage.setItem("leadEmail", "")
      localStorage.setItem("leadStatus2", "")
      localStorage.setItem("leadDay", "")
      localStorage.setItem("leadStatus3", "")
      localStorage.setItem("leadmaturedby", "")
      localStorage.setItem("ledgerType", "")
      localStorage.setItem("offerType", "")
      localStorage.setItem("PrductGST", "")
      localStorage.setItem("OrderStatus", "")
      localStorage.setItem("OrderPstatus", "")
      localStorage.setItem("leadSort", "")
      localStorage.setItem("leadID", "")
      localStorage.setItem("leadTransfer", "")
      localStorage.setItem("leadFlag", "")
      localStorage.setItem("leadUpdateSince", "")
      localStorage.setItem("leadOrderSince", "")
      localStorage.setItem("leadPaymentSince", "")
      localStorage.setItem("leadFirmName", "")
      localStorage.setItem("leadCreated_at", "")
      localStorage.setItem("leadMatured_at", "")
      localStorage.setItem("updatedBy", "")
      localStorage.setItem(
        "leadAssigned_at",
        JSON.stringify({
          startDate: "",
          endDate: "",
        })
      )
      localStorage.setItem("current_page", "")
      localStorage.setItem("leadAction", "")
      localStorage.setItem("leadAssigned", "")
      localStorage.setItem("CompanyPool", "")
      localStorage.setItem("leadfields_filter", JSON.stringify({ data: "" }))
      localStorage.setItem(
        "leadImportant_date",
        JSON.stringify({
          from: "",
          to: "",
        })
      )
    }
  }, [branchData])

  useEffect(() => {
    showAllBranch()

    const savedBranchId = localStorage.getItem("selectedBranch")
    if (savedBranchId && !branchData) {
      showBranch(savedBranchId)
    }
  }, [branchData])

  const handleBranchChange = id => {
    if (id === "") {
      setSelectedBranch(null)
      localStorage.setItem("selectedBranch", "")
      setBranchData(null)
      setTimeout(() => {
        window.location.reload()
      }, 0)
    } else {
      showBranch(id)
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }
  }

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      {modal_standard ? (
        <LeadModal
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
        />
      ) : (
        ""
      )}
      {modal2 ? <LocationModal modal2={modal2} setmodal2={setmodal2} /> : ""}
      {migration_modal ? (
        <MigrationModal
          migration_modal={migration_modal}
          setmigration_modal={setmigration_modal}
        />
      ) : (
        ""
      )}
      <div
        className="topnav"
        style={{
          marginTop: `${
            getCurrentUser()?.expiry_message !== null ? "18px" : "0px"
          }`,
        }}
      >
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav w-100">
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="ti-bar-chart me-2" />
                    {props.t("Dashboard")} {props.menuOpen}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setfollow(!follow)
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="ti-harddrives me-2"></i>
                    {props.t("Follow Ups")}
                  </Link>
                  <div className={classname("dropdown-menu", { show: follow })}>
                    <div className="dropdown">
                      <Link to="/followups-today" className="dropdown-item">
                        {props.t("Today's Followup")}{" "}
                        <span className="text-primary">
                          ({counter?.today_followup})
                        </span>
                      </Link>
                    </div>
                    <div className="dropdown">
                      <Link to="/followups-future" className="dropdown-item">
                        {props.t("Future Followup")}{" "}
                        <span className="text-primary">
                          ({counter?.future_followup})
                        </span>
                      </Link>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setcomponent(!component)
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="ti-harddrives me-2"></i>
                    {props.t("Leads")}
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: component,
                    })}
                  >
                    <div className="dropdown">
                      {getPermission() &&
                      getPermission().lead.lead.create.is_checked === "yes" ? (
                        <span
                          onClick={() => setmodal_standard(true)}
                          className="dropdown-item"
                          style={{ cursor: "pointer" }}
                        >
                          {props.t("Add New Lead")}
                        </span>
                      ) : (
                        ""
                      )}
                      {getCurrentUser()?.role_name === "Administrator" ||
                      getCurrentUser()?.role_name === "Sub Administrator" ? (
                        <Link to="/statistics" className="dropdown-item">
                          {props.t("All Statistics")}{" "}
                          <span className="text-primary">
                            ({counter.all_leads})
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      <Link to="/leads?id=&reload=1" className="dropdown-item">
                        {props.t("All Leads")}{" "}
                        <span className="text-primary">
                          ({counter.all_leads})
                        </span>
                      </Link>
                      {/* <Link
                           to="/#"
                           className="dropdown-item dropdown-toggle arrow-none"
                           onClick={e => {
                           e.preventDefault()
                           setchart(!chart)
                           }}
                        >
                           {props.t("Duplicate Leads")}{" "}
                           <div className="arrow-down"></div>
                        </Link>
                        */}
                      {getPermission() &&
                      getPermission().repeated_lead.repeated_lead.view
                        .is_checked === "yes" ? (
                        <Link to="/repeated-leads" className="dropdown-item">
                          {props.t("Repeated Leads")}{" "}
                          <span className="text-primary">
                            ({counter.repeated_leads})
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      {getPermission() &&
                      getPermission().repeated_lead.repeated_lead.view
                        .is_checked === "yes" ? (
                        <Link to="/duplicated-leads" className="dropdown-item">
                          {props.t("Duplicated Leads")}{" "}
                          <span className="text-primary">
                            ({counter.duplicate})
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      {getPermission() &&
                      getPermission().company_pool.company_pool.view
                        .is_checked === "yes" ? (
                        <Link to="/company-leads" className="dropdown-item">
                          {props.t("Company Pool")}{" "}
                          <span className="text-primary">
                            ({counter.company_pool})
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                      <Link to="/transfer-request" className="dropdown-item">
                        {props.t("Transfer Request")}{" "}
                        <span className="text-primary">
                          ({counter.transfer_request})
                        </span>
                      </Link>
                      {getPermission() &&
                      getPermission().my_lead.my_lead.view.is_checked ===
                        "yes" ? (
                        <>
                          {getCurrentUser()?.role_name == "Executive" ? (
                            ""
                          ) : (
                            <Link to="/my-leads" className="dropdown-item">
                              {props.t("My Leads")}{" "}
                              <span className="text-primary">
                                ({counter.my_leads})
                              </span>
                            </Link>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {getPermission() &&
                      getPermission().deleted_lead.deleted_lead.view
                        .is_checked === "yes" ? (
                        <Link to="/deleted-leads" className="dropdown-item">
                          {props.t("Trashed Leads")}{" "}
                          <span className="text-primary">
                            ({counter.deleted_leads})
                          </span>
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </li>

                {/* {getCurrentUser() && getCurrentUser().location == 1 ? ( */}

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/occupied-locations"
                    // onClick={e => {
                    //   e.preventDefault()
                    //   setauth(!auth)
                    // }}
                  >
                    <i className="ti-archive me-2"></i>{" "}
                    {props.t("Occupied Locations")} {props.menuOpen}
                  </Link>
                </li>

                {/* ) : (
                           ""
                        )} */}
                {getPermission()?.dead_request_lead?.dead_request_lead?.view
                  ?.is_checked === "yes" ||
                getPermission()?.dead_lead?.dead_lead?.view?.is_checked ===
                  "yes" ||
                getPermission()?.winback_pool?.winback_pool?.view
                  ?.is_checked === "yes" ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setextra(!extra)
                      }}
                    >
                      <i className="ti-support me-2"></i>{" "}
                      {props.t("Dead Leads")}
                    </Link>
                    <div
                      className={classname(
                        "dropdown-menu mega-dropdown-menu px-2 dropdown-mega-menu",
                        {
                          show: extra,
                        }
                      )}
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div>
                            {getPermission() &&
                            getPermission().dead_request_lead.dead_request_lead
                              .view.is_checked === "yes" ? (
                              <Link
                                to="/dead-requests"
                                className="dropdown-item"
                              >
                                {props.t("Requests")}{" "}
                                <span className="text-primary">
                                  ({counter.dead_request_leads})
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                            {getPermission() &&
                            getPermission().dead_lead.dead_lead.view
                              .is_checked === "yes" ? (
                              <Link to="/dead-leads" className="dropdown-item">
                                {props.t("Dead")}{" "}
                                <span className="text-primary">
                                  ({counter.dead_leads})
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                            {getPermission() &&
                            getPermission().winback_pool.winback_pool.view
                              .is_checked === "yes" ? (
                              <Link
                                to="/winback-leads"
                                className="dropdown-item"
                              >
                                {props.t("Win Back Pool")}{" "}
                                <span className="text-primary">
                                  ({counter.winback_pool})
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/#"
                    id="topnav-emailtemplates"
                    role="button"
                    onClick={e => {
                      e.preventDefault()
                      setemail(!email)
                    }}
                  >
                    <i className="ti-bookmark-alt me-2"></i>Daily Report
                  </Link>
                  <div
                    className={classname("dropdown-menu", {
                      show: email,
                    })}
                    aria-labelledby="topnav-emailtemplates"
                  >
                    <Link to="/today-report" className="dropdown-item">
                      {props.t("Today's Report")}{" "}
                      {/* <span className="text-primary">({counter.today_report})</span> */}
                    </Link>
                    <Link to="/past-report" className="dropdown-item">
                      {props.t("Past 30 Days Reports")}{" "}
                      {/* <span className="text-primary">({counter.past_report})</span> */}
                    </Link>
                    {getCurrentUser()?.role_name === "Administrator" ? (
                      <Link to="/activity-logs" className="dropdown-item">
                        {props.t("Activity log")}{" "}
                        {/* <span className="text-primary">({counter.past_report})</span> */}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </li>

                {/* {getCurrentUser() && getCurrentUser().role_name === "Administrator" ?
                           <li className="nav-item">
                              <Link className="nav-link" to="/plans">
                                 <i className="ti-panel me-2" />
                                 {props.t("Plans")} {props.menuOpen}
                              </Link>
                           </li>
                           : ""} */}
                {/* <li className="nav-item">
                              <Link className="nav-link" to="/notifications">
                                 <i className="ti-bell me-2" />
                                 {props.t("Notifications")} {props.menuOpen}
                              </Link>
                           </li> */}
                {getPermission() &&
                getPermission().admin.admin.view.is_checked === "yes"
                  ? // <li className="nav-item dropdown mega-dropdown">
                    //    <Link
                    //       to="/#"
                    //       className="nav-link"
                    //       onClick={e => {
                    //          e.preventDefault()
                    //          setadmin(!admin)
                    //       }}
                    //    >
                    //       <i className="ti-user me-2" />
                    //       {props.t("Admin")} {props.menuOpen}
                    //    </Link>
                    //    <div
                    //       className={classname(
                    //          "dropdown-menu mega-dropdown-menu px-2 dropdown-menu-start dropdown-mega-menu",
                    //          { show: admin }
                    //       )}
                    //    >
                    //       <div className="dropdown">
                    //          <Link to="/staff" className="dropdown-item">
                    //             {props.t("Staff")}{" "}
                    //             {/* <span className="text-primary">({counter.staff})</span> */}
                    //          </Link>
                    //       </div>
                    //       {getCurrentUser()?.role_name === "Administrator" ?
                    //          <div className="dropdown">
                    //             <Link to="/role" className="dropdown-item">
                    //                {props.t("Permissions")}{" "}
                    //                {/* <span className="text-primary">({counter.all_roles})</span> */}
                    //             </Link>
                    //          </div>
                    //          : null}
                    //       <div className="dropdown">
                    //          <Link to="/scheme" className="dropdown-item">
                    //             {props.t("Staff Schemes")}{" "}
                    //             {/* <span className="text-primary">({counter.schemes})</span> */}
                    //          </Link>
                    //       </div>
                    //       <div className="dropdown">
                    //          <Link
                    //             to="/#"
                    //             onClick={e => {
                    //                e.preventDefault()
                    //                setleadSetting(!leadSetting)
                    //             }}
                    //             className="dropdown-item"
                    //          >
                    //             {props.t("Leads Setting")}
                    //             <div className="arrow-down"></div>
                    //          </Link>
                    //          <div
                    //             // className="dropdown-menu"
                    //             className={classname("dropdown-menu", {
                    //                show: leadSetting,
                    //             })}
                    //          >
                    //             <div className="dropdown">
                    //                {getCurrentUser() &&
                    //                   getCurrentUser().migrate_data == 1 ? (
                    //                   <span
                    //                      onClick={() => setmigration_modal(true)}
                    //                      className="dropdown-item"
                    //                      style={{ cursor: "pointer" }}
                    //                   >
                    //                      {props.t("Migration Form")}
                    //                   </span>
                    //                ) : (
                    //                   ""
                    //                )}
                    //             </div>
                    //             {getCurrentUser() &&
                    //                getCurrentUser().location == 1 ? (
                    //                <div className="dropdown">
                    //                   <span
                    //                      onClick={() => setmodal2(true)}
                    //                      className="dropdown-item"
                    //                      style={{ cursor: "pointer" }}
                    //                   >
                    //                      {props.t("Add New Location")}
                    //                   </span>
                    //                </div>
                    //             ) : (
                    //                ""
                    //             )}
                    //             <div className="dropdown">
                    //                <Link to="/dead" className="dropdown-item">
                    //                   {props.t("Dead Reasons")}{" "}
                    //                   {/* <span className="text-primary">({counter.dead_status})</span> */}
                    //                </Link>
                    //             </div>
                    //             <div className="dropdown">
                    //                <Link
                    //                   to="/followup-status"
                    //                   className="dropdown-item"
                    //                >
                    //                   {props.t("Followup Stages")}{" "}
                    //                   {/* <span className="text-primary">({counter.followup_status})</span> */}
                    //                </Link>
                    //             </div>
                    //             <div className="dropdown">
                    //                <Link to="/mature-status" className="dropdown-item">
                    //                   {props.t("Mature Stages")}{" "}
                    //                   {/* <span className="text-primary">({counter.mature_status})</span> */}
                    //                </Link>
                    //             </div>
                    //             {getCurrentUser() && getCurrentUser().tag == 1 ? (
                    //                <div className="dropdown">
                    //                   <Link to="/lead-tag" className="dropdown-item">
                    //                      {props.t("Lead Tag")}{" "}
                    //                      {/* <span className="text-primary">({counter.lead_tag})</span> */}
                    //                   </Link>
                    //                </div>
                    //             ) : (
                    //                ""
                    //             )}
                    //             <div className="dropdown">
                    //                <Link to="/lead-fields" className="dropdown-item">
                    //                   {props.t("Custom Fields")}{" "}
                    //                   {/* <span className="text-primary">({counter.lead_field})</span> */}
                    //                </Link>
                    //             </div>
                    //             <div className="dropdown">
                    //                <Link to="/lead-source" className="dropdown-item">
                    //                   {props.t("Lead Source")}{" "}
                    //                   {/* <span className="text-primary">({counter.lead_source})</span> */}
                    //                </Link>
                    //             </div>
                    //             <div className="dropdown">
                    //                <Link to="/lead-label" className="dropdown-item">
                    //                   {props.t("Lead Label")}{" "}
                    //                   {/* <span className="text-primary">({counter.lead_label})</span> */}
                    //                </Link>
                    //             </div>
                    //          </div>
                    //       </div>
                    //       <div className="dropdown">
                    //          <Link to="/global-ip" className="dropdown-item">
                    //             {props.t("Global IP")}{" "}
                    //             {/* <span className="text-primary">({counter.global_ip})</span> */}
                    //          </Link>
                    //       </div>
                    //       <div className="dropdown">
                    //          <Link to="/global-security" className="dropdown-item">
                    //             {props.t("Day & Time")}{" "}
                    //             {/* <span className="text-primary">({counter.global_security})</span> */}
                    //          </Link>
                    //       </div>
                    //       {localStorage.getItem("company_name") === "maxnova" ? (
                    //          <div className="dropdown">
                    //             <Link to="/reminder" className="dropdown-item">
                    //                {props.t("Reminder")}{" "}
                    //                {/* <span className="text-primary">({counter.reminder})</span> */}
                    //             </Link>
                    //          </div>
                    //       ) : (
                    //          ""
                    //       )}
                    //       {getCurrentUser() && getCurrentUser().fetch_email == 1 ? (
                    //          <>
                    //             <div className="dropdown">
                    //                <Link
                    //                   to="/fetch-from-email"
                    //                   className="dropdown-item"
                    //                >
                    //                   {props.t("Fetch From Email")}{" "}
                    //                   {/* <span className="text-primary">({counter.fetch_from_email})</span> */}
                    //                </Link>
                    //             </div>
                    //             <div className="dropdown">
                    //                <Link
                    //                   to="/fetch-from-api"
                    //                   className="dropdown-item"
                    //                >
                    //                   {props.t("Fetch From API")}{" "}
                    //                   {/* <span className="text-primary">({counter.fetch_from_email})</span> */}
                    //                </Link>
                    //             </div>
                    //          </>
                    //       ) : (
                    //          ""
                    //       )}
                    //       <div className="dropdown">
                    //          <Link to="/login-logs" className="dropdown-item">
                    //             {props.t("Login Logs")}{" "}
                    //             {/* <span className="text-primary">({counter.all_loginlogs})</span> */}
                    //          </Link>
                    //       </div>
                    //       <div className="dropdown">
                    //          <Link to="/klik-drive" className="dropdown-item">
                    //             {props.t("Klik Drive")}{" "}
                    //             {/* <span className="text-primary">({counter.klik_drive})</span> */}
                    //          </Link>
                    //       </div>
                    //       <div className="dropdown">
                    //          <Link to="/messages" className="dropdown-item">
                    //             {props.t("Create Messages")}{" "}
                    //             {/* <span className="text-primary">({counter.all_loginlogs})</span> */}
                    //          </Link>
                    //       </div>
                    //       <div className="dropdown">
                    //          <Link to="/campaigns" className="dropdown-item">
                    //             {props.t("Campaigns")}{" "}
                    //             {/* <span className="text-primary">({counter.all_loginlogs})</span> */}
                    //          </Link>
                    //       </div>
                    //    </div>
                    // </li>
                    ""
                  : ""}
                {getCurrentUser().crm_permission ||
                getCurrentUser().crmpro_permission == 1 ||
                getCurrentUser().crmpronew_permission == 1 ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setcrm(!crm)
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="fas fa-users  me-2"></i>
                      {props.t("CRM")}
                    </Link>
                    <div className={classname("dropdown-menu", { show: crm })}>
                      <div className="dropdown">
                        <Link to="/customers" className="dropdown-item">
                          {props.t("Customers")}{" "}
                          {/* <span className="text-primary">({counter.customer})</span> */}
                        </Link>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault()
                            setcategorie(!categorie)
                          }}
                          className="dropdown-item"
                        >
                          {props.t("Categories")}{" "}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          // className="dropdown-menu"
                          className={classname("dropdown-menu", {
                            show: categorie,
                          })}
                        >
                          <div className="dropdown">
                            <Link to="/all-category" className="dropdown-item">
                              {props.t("All Categories")}{" "}
                            </Link>
                          </div>
                          <div className="dropdown">
                            <Link to="/sub-category" className="dropdown-item">
                              {props.t("Sub-Categories")}{" "}
                              {/* <span className="text-primary">({counter.all_sub_categories})</span> */}
                            </Link>
                          </div>
                          <div className="dropdown">
                            <Link to="/all-packing" className="dropdown-item">
                              {props.t("All Packing")}{" "}
                              {/* <span className="text-primary">({counter.all_packing})</span> */}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link to="/orders" className="dropdown-item">
                          {props.t(
                            getCurrentUser()?.crm_order_title
                              ? getCurrentUser()?.crm_order_title
                              : "Orders"
                          )}{" "}
                          {/* <span className="text-primary">({counter.order})</span> */}
                        </Link>
                      </div>
                      <div className="dropdown">
                        <Link to="/ledgers" className="dropdown-item">
                          {props.t("Ledger")}{" "}
                          {/* <span className="text-primary">({counter.ledger})</span> */}
                        </Link>
                      </div>
                      <div className="dropdown">
                        <Link to="/proformas" className="dropdown-item">
                          {props.t(
                            getCurrentUser()?.crm_proforma_title
                              ? getCurrentUser()?.crm_proforma_title
                              : "Proformas"
                          )}{" "}
                          {/* <span className="text-primary">({counter.proforma})</span> */}
                        </Link>
                      </div>
                      {getCurrentUser().crmpro_products_enabled == 1 ? (
                        <div className="dropdown">
                          <Link to="/products" className="dropdown-item">
                            {props.t("Product List")}{" "}
                            {/* <span className="text-primary">({counter.all_products})</span> */}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      {getCurrentUser().crmpro_services_enabled == 1 ? (
                        <div className="dropdown">
                          <Link to="/product-line" className="dropdown-item">
                            {props.t("Service Line")}{" "}
                            {/* <span className="text-primary">({counter.all_products})</span> */}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="dropdown">
                        <Link to="/offers" className="dropdown-item">
                          {props.t("Offers")}{" "}
                          {/* <span className="text-primary">({counter.all_offers})</span> */}
                        </Link>
                      </div>
                      <div className="dropdown">
                        <Link to="/schemes" className="dropdown-item">
                          {props.t("Customer Schemes")}{" "}
                          {/* <span className="text-primary">({counter.customer_schemes})</span> */}
                        </Link>
                      </div>
                      <div className="dropdown">
                        <Link to="/complaints" className="dropdown-item">
                          {props.t("Complaints")}{" "}
                          {/* <span className="text-primary">({counter.complaints})</span> */}
                        </Link>
                      </div>
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {(getPermission() &&
                  getPermission().admin.admin.view.is_checked === "yes") ||
                getCurrentUser().role_name === "Branch Manager" ? (
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/settings"
                        // onClick={e => {
                        //   e.preventDefault()
                        //   setauth(!auth)
                        // }}
                      >
                        <i className="ti-user me-2"></i>{" "}
                        {props.t("Admin Settings")} {props.menuOpen}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link">
                        <select
                          style={{
                            display: "inline-block",
                            width: "auto",
                            background: "transparent",
                            border: "none",
                            color: "inherit",
                            outline: "none",
                            fontSize: "inherit",
                          }}
                          value={selectedBranch?.id || ""}
                          onChange={e => {
                            const selectedId = e.target.value
                            handleBranchChange(selectedId)
                          }}
                        >
                          <option style={{ color: "lightseagreen" }} value="">
                            All Branches
                          </option>
                          {branches &&
                            branches.map(branch => (
                              <option
                                style={{ color: "lightseagreen" }}
                                key={branch.id}
                                value={branch.id}
                              >
                                {branch.name}
                              </option>
                            ))}
                        </select>
                      </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}
                {getCurrentUser()?.role_name !== "Administrator" &&
                getCurrentUser()?.role_name !== "Branch Manager" ? (
                  getPermission()?.lead_label.lead_label.view.is_checked ===
                    "yes" ||
                  getPermission()?.lead_source.lead_source.view.is_checked ===
                    "yes" ? (
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setSetting(!setting)
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="fa fa-cog me-2"></i>
                        {props.t("Settings")}
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: setting,
                        })}
                      >
                        {getPermission() &&
                        getPermission().lead_label.lead_label.view
                          .is_checked === "yes" ? (
                          <div className="dropdown">
                            <Link to="/lead-source" className="dropdown-item">
                              {props.t("Lead Source")}{" "}
                              <span className="text-primary">
                                {/* ({counter?.today_followup}) */}
                              </span>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                        {getPermission() &&
                        getPermission().lead_source.lead_source.view
                          .is_checked === "yes" ? (
                          <div className="dropdown">
                            <Link to="/lead-label" className="dropdown-item">
                              {props.t("Lead Label")}{" "}
                              <span className="text-primary">
                                {/* ({counter?.today_followup}) */}
                              </span>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
