import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import StaffModal from "./staffmodal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToggleSwitch from "../../Toggle-Switch"

const StaffList = props => {
  const [user, setUser] = useState(props.data)
  const [modal_standard, setmodal_standard] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteUser = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUser("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          props.getAllUser()
        } else {
          setdynamic_description(res.data.message)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const unblockUser = e => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/unblock-user/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          props.getAllUsers()
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editRole = e => {
    setIsOpen(true)
    setmodal_standard(true)
  }

  const updateOtp = e => {
    if (!getCurrentUser()?.email_enabled) {
      toast.error(
        "Your SMTP details are not enabled, please add SMTP details in your profile.",
        {
          position: "top-center",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      )
      return false
    }
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/enable-otp`,
        {
          id: user.id,
          enable_otp: isActive,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const updateUser = e => {
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/restrict-user`,
        {
          id: user.id,
          restriction: isActive,
          restriction_ip: user.restriction_ip,
          restriction_timing: user.restriction_timing,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }
  const updateUser2 = e => {
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/restrict-user`,
        {
          id: user.id,
          restriction: user.restriction,
          restriction_ip: isActive,
          restriction_timing: user.restriction_timing,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }
  const updateUser3 = e => {
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/restrict-user`,
        {
          id: user.id,
          restriction: user.restriction,
          restriction_ip: user.restriction_ip,
          restriction_timing: isActive,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  if (user === "") {
    return ""
  } else
    return (
      <>
        {isOpen ? (
          <StaffModal
            modal_standard={modal_standard}
            setmodal_standard={setmodal_standard}
            user={user}
            setUser={setUser}
            setIsOpen={setIsOpen}
          />
        ) : null}
        <tr key={props.i}>
          <td>{props.counter + props.i}</td>
          <td>
            {user.live === "yes" || getCurrentUser()?.id == user.id ? (
              <span
                className="text-success"
                style={{ fontSize: "40px", padding: "0px 0px 0px 6px" }}
              >
                •
              </span>
            ) : (
              <span
                className="text-danger"
                style={{ fontSize: "40px", padding: "0px 0px 0px 6px" }}
              >
                •
              </span>
            )}
          </td>
          <td>
            <img
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={user.photo}
            ></img>
          </td>
          <td>
            {user.role_name === "Administrator" ? (
              <span>{user.name}</span>
            ) : (
              <span
                className="text-info"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  editRole(user.id)
                }}
              >
                {user.name}
              </span>
            )}
            {user.login_attempts > 4 ? (
              <>
                <br />
                <span
                  className="btn btn-sm btn-success"
                  onClick={() => unblockUser(user.id)}
                >
                  Unblock User
                </span>
              </>
            ) : (
              ""
            )}
            <br />
            <span className="fw-bold">ID- </span>
            {user.id}
          </td>
          <td>{user.email}</td>
          <td>{user.phone}</td>
          <td>{user.last_seen_at}</td>
          <td>
            {user.teamleader == null ? "---" : <span>{user.teamleader}</span>}
          </td>
          <td>{user.role_name}</td>
          <td>
            {user.branch_assignee
              ? user.branch_assignee.map((branches, i) => (
                  <div key={i}>{branches.branch.name}</div>
                ))
              : "No branch"}
          </td>

          <td>
            {user.status === "0" ? (
              <span className="badge bg-danger">Blocked</span>
            ) : (
              <span className="badge bg-success">Active</span>
            )}
          </td>
          {getCurrentUser()?.crmpro_permission === 1 ||
          getCurrentUser()?.crmpronew_permission === 1 ? (
            <td>
              <ToggleSwitch
                id={`${user.id}-otp`}
                name="otp"
                checked={user.enable_otp === 1 ? true : false}
                onChange={e => updateOtp(e)}
                value={user.enable_otp}
              />
            </td>
          ) : (
            ""
          )}
          <td>
            {user.role_name === "Administrator" ? (
              ""
            ) : (
              <ToggleSwitch
                id={`${user.id}-restriction`}
                name="restriction"
                checked={user.restriction === 1 ? true : false}
                onChange={e => updateUser(e)}
                value={user.restriction}
              />
            )}
          </td>
          <td>
            {user.role_name === "Administrator" ? (
              ""
            ) : (
              <>
                {user.restriction === 1 ? (
                  <ToggleSwitch
                    id={`${user.id}-restriction_ip`}
                    name="restriction_ip"
                    checked={user.restriction_ip === 1 ? true : false}
                    onChange={e => updateUser2(e)}
                    value={user.restriction_ip}
                  />
                ) : (
                  <ToggleSwitch
                    id={`${user.id}-restriction_ip`}
                    name="restriction_ip"
                    disabled
                    checked={user.restriction_ip === 1 ? true : false}
                    onChange={e => updateUser2(e)}
                    value={user.restriction_ip}
                  />
                )}
              </>
            )}
          </td>
          <td>
            {user.role_name === "Administrator" ? (
              ""
            ) : (
              <>
                {user.restriction === 1 ? (
                  <ToggleSwitch
                    id={`${user.id}-restriction_timing`}
                    name="restriction_timing"
                    checked={user.restriction_timing === 1 ? true : false}
                    onChange={e => updateUser3(e)}
                    value={user.restriction_timing}
                  />
                ) : (
                  <ToggleSwitch
                    id={`${user.id}-restriction_timing`}
                    name="restriction_timing"
                    disabled
                    checked={user.restriction_timing === 1 ? true : false}
                    onChange={e => updateUser3(e)}
                    value={user.restriction_timing}
                  />
                )}
              </>
            )}
          </td>
          <td>{user.member_since}</td>
          <td>
            {user.role_name === "Administrator" ? (
              ""
            ) : (
              <>
                <a
                  className="text-warning"
                  onClick={() => {
                    editRole(user.id)
                  }}
                >
                  <i className="fas fa-pen" />
                </a>
                <a
                  className="text-danger ms-2"
                  onClick={() => {
                    setconfirm_alert(true)
                  }}
                >
                  <i className="fas fa-trash-alt" />
                </a>
                {confirm_alert ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      setconfirm_alert(false)
                      setsuccess_dlg(true)
                      deleteUser(user.id)
                      setdynamic_title("Deleted")
                      setdynamic_description("Your file has been deleted.")
                    }}
                    onCancel={() => setconfirm_alert(false)}
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
              </>
            )}
          </td>
        </tr>
      </>
    )
}
export default StaffList
