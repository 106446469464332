import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import MstatusModal from "./MstatusModal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const MstatusList = props => {
  const [status, setStatus] = useState(props.data)
  const [modal_mature_status, setmodal_mature_status] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [followup_alert, setfollowup_alert] = useState(false)
  const [leads_alert, setleads_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteLeads = e => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/mature-lead-deleted/${e} `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.getAllStatus()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const deleteFollowups = e => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/mature-followup-deleted/${e} `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.getAllStatus()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const deleteStatus = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/mature-status/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setStatus("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editStatus = e => {
    setIsOpen(true)
    setmodal_mature_status(true)
  }

  useEffect(() => {
    selectedRoleId = []
  }, [])

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e)
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id
      })
      selectedRoleId = uniqueID
      props.setLeadsSelectedIds(selectedRoleId)
    } else {
      selectedRoleId = props.leadsSelectedIds
      var array = selectedRoleId
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
      props.setLeadsSelectedIds(selectedRoleId)
    }
    if (selectedRoleId.length == 0) {
      props.setBlur(false)
    } else {
      props.setBlur(true)
    }
  }

  if (status === "") {
    return ""
  } else
    return (
      <>
        {isOpen ? (
          <MstatusModal
            modal_mature_status={modal_mature_status}
            setmodal_mature_status={setmodal_mature_status}
            status={status}
            setStatus={setStatus}
            setIsOpen={setIsOpen}
          />
        ) : null}
        <tr key={props.i}>
          <td>
            {status.name == "Order Received" ? (
              ""
            ) : (
              <input
                onChange={e => getSelectedId(status.id, e)}
                name="chk"
                value={status.id}
                className="p-0 d-inline-block"
                type="checkbox"
              />
            )}
          </td>
          <td>{props.counter + props.i}</td>
          <td>{status.name}</td>
          <td>{status.followup_count}</td>
          <td>{status.branch?.name}</td>
          <td>{status.lead_count}</td>
          {/* <td>
               <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => setleads_alert(true)}
               >
                  Delete Leads
               </button>
            </td>
            <td>
               <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => setfollowup_alert(true)}
               >
                  Delete Followups
               </button>
            </td> */}
          <td>
            {status.name == "Order Received" ? (
              ""
            ) : (
              <>
                {getPermission().mature_status.mature_status.edit.is_checked ===
                "yes" ? (
                  <a
                    className="text-warning"
                    onClick={() => {
                      editStatus(status.id)
                    }}
                  >
                    <i className="fas fa-pen" />
                  </a>
                ) : (
                  ""
                )}
                {getPermission().mature_status.mature_status.delete
                  .is_checked === "yes" ? (
                  <a
                    className="text-danger ms-2"
                    onClick={() => {
                      setconfirm_alert(true)
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </a>
                ) : (
                  ""
                )}
              </>
            )}
          </td>
          {leads_alert ? (
            <SweetAlert
              title="Are you sure to delete these mature-statuses ?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setleads_alert(false)
                setsuccess_dlg(true)
                deleteLeads(status.id)
                setdynamic_title("Deleted")
                setdynamic_description("Your file has been deleted.")
              }}
              onCancel={() => setleads_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          {followup_alert ? (
            <SweetAlert
              title="Are you sure to delete these followups ?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setfollowup_alert(false)
                setsuccess_dlg(true)
                deleteFollowups(status.id)
                setdynamic_title("Deleted")
                setdynamic_description("Your file has been deleted.")
              }}
              onCancel={() => setfollowup_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
          {confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setconfirm_alert(false)
                setsuccess_dlg(true)
                deleteStatus(status.id)
                setdynamic_title("Deleted")
                setdynamic_description("Your file has been deleted.")
              }}
              onCancel={() => setconfirm_alert(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </tr>
      </>
    )
}
export default MstatusList
