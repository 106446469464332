import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import MultipleValueTextInput from "react-multivalue-text-input"
import Select from "react-select"

export default function DeadModal(props) {
  const [loading, setLoading] = useState(false)
  const [allCheck, setAllCheck] = useState([])
  const [branches, setBranches] = useState([])
  const [error2, setError2] = useState("")
  const [selectedBranches, setSelectedBranches] = useState()
  const [newmodal_element, setnewModal_element] = useState(false)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  const submitDead = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.dead !== undefined) {
      const formdata = new FormData()
      formdata.append("branch_id", selectedBranches)

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/dead-status/${props.dead.id}?reason=${value.reason}`,
          formdata,
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setIsOpen(false)
            props.setmodal_dead_reason(false)
            props.setDead(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/dead-status`,
          {
            reason: allCheck,
            branch_id: selectedBranches,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllDead()
            props.setmodal_dead_reason(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }
  const showAllBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token || ""}`,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          const branchOptions = res.data.data.map(branch => ({
            label: branch.name,
            value: branch.id,
          }))
          setBranches(branchOptions)
        } else {
          setError2(res.data.message)
        }
      })
  }

  const handleSelectChange = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setSelectedBranches(selectedValues)
  }
  const handleSelectChanges = e => {
    const selectedValue = parseInt(e.target.selectedOptions[0]?.value, 10)
    setSelectedBranches([selectedValue])
  }
  useEffect(() => {
    showAllBranch()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_dead_reason(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])
  return (
    <>
      <Modal isOpen={props.modal_dead_reason} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitDead(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.dead !== undefined
                ? "Edit Dead Reason"
                : "Create Dead Reason"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_dead_reason(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {props.dead !== undefined ? (
              <>
                <div className="mb-3">
                  <AvField
                    label="Reason"
                    type="text"
                    name="reason"
                    defaultValue={props.dead && props.dead.reason}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="branch-select" className="form-label">
                    Branch
                  </label>
                  <AvField
                    type="select"
                    name="branch"
                    id="branch-select"
                    onChange={handleSelectChanges}
                  >
                    <option>Select Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch.value}>
                        {branch.label}
                      </option>
                    ))}
                  </AvField>
                </div>
              </>
            ) : (
              <>
                <div className="mb-3">
                  <MultipleValueTextInput
                    required
                    onItemAdded={(item, allItems) =>
                      console.log(
                        `Item added: ${(item, allItems)}`,
                        setAllCheck(allItems)
                      )
                    }
                    onItemDeleted={(item, allItems) =>
                      console.log(
                        `Item removed: ${item}`,
                        setAllCheck(allItems)
                      )
                    }
                    label="Add Dead Reason(s)"
                    name="value"
                    className="form-control"
                    placeholder="Enter names you want"
                  />
                  <span className="fst-italic">
                    Use comma (,) to insert a value.
                  </span>
                </div>
                <div className="mb-3">
                  <label htmlFor="branch-select" className="form-label">
                    Branch
                  </label>
                  <Select
                    isMulti
                    options={branches}
                    onChange={handleSelectChange}
                    placeholder="Select Branches"
                  />
                </div>
              </>
            )}
            <div className="modal-footer">
              <div className="text-center">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_dead_reason(false)}
                  >
                    {props.dead !== undefined ? "Submit" : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
