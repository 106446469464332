import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import Loader from "../../Loader/loader.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"

let categoryNames = []

export default function AllCateModal(props) {
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState("")
  const [cateName, setCateName] = useState({ formValues: [{ name: "" }] })
  const [branches, setBranches] = useState([])
  const [error2, setError2] = useState("")
  const [selectedBranches, setSelectedBranches] = useState()
  const [newmodal_element, setnewModal_element] = useState(false)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  const handleChange = (i, e) => {
    let formValues = cateName.formValues
    formValues[i][e.target.name] = e.target.value
    setCateName({ formValues })
  }
  const addFormFields = () => {
    setCateName({
      formValues: [...cateName.formValues, { name: "" }],
    })
  }
  const removeFormFields = i => {
    setLoader(<Loader />)
    let formValues = cateName.formValues
    formValues.splice(i, 1)
    setTimeout(() => {
      setCateName({ formValues })
      setLoader("")
    }, 500)
  }

  const submitCategory = (e, value) => {
    if (cateName.formValues[0].name !== "") {
      categoryNames = cateName.formValues.map(form => form.name)
    }
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.category !== undefined) {
      const formdata = new FormData()
      formdata.append("branch_id", selectedBranches)
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/category/${props.category.id}?gst=${value.gst}&name=${
            value.name
          }&branch_id=${value.branch}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setIsOpen(false)
            props.setmodal_standard(false)
            props.setCategory(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/category`,
          {
            gst: value.gst,
            names: categoryNames,
            branch_id: selectedBranches,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllCate()
            props.setmodal_standard(false)
            setCateName({ formValues: [{ name: "" }] })
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            categoryNames = []
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }
  const showAllBranch = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token || ""}`,
          },
        }
      )
      .then(res => {
        if (res.data.status) {
          const branchOptions = res.data.data.map(branch => ({
            label: branch.name,
            value: branch.id,
          }))
          setBranches(branchOptions)
        } else {
          setError2(res.data.message)
        }
      })
  }

  const handleSelectChange = selectedOptions => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setSelectedBranches(selectedValues)
  }
  const handleSelectChanges = e => {
    const selectedValue = parseInt(e.target.selectedOptions[0]?.value, 10)
    setSelectedBranches([selectedValue])
  }
  useEffect(() => {
    showAllBranch()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_standard(false)
        setCateName({ formValues: [{ name: "" }] })
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal isOpen={props.modal_standard} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitCategory(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.category !== undefined
                ? "Edit category"
                : "Create category"}
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => {
                props.setmodal_standard(false),
                  setCateName({ formValues: [{ name: "" }] })
              }}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <AvField
                className="select-arrow"
                label="GST"
                type="select"
                name="gst"
                value={props.category && props.category.gst}
              >
                <option value="" hidden>
                  Select
                </option>
                <option value="0">0%</option>
                <option value="0.1">0.1%</option>
                <option value="0.25">0.25%</option>
                <option value="5">5%</option>
                <option value="12">12%</option>
                <option value="18">18%</option>
                <option value="28">28%</option>
              </AvField>
            </div>
            {props.category !== undefined ? (
              <>
                <div className="mb-3">
                  <AvField
                    label="Category"
                    type="text"
                    name="name"
                    value={props.category && props.category.name}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="branch-select" className="form-label">
                    Branch
                  </label>
                  <AvField
                    type="select"
                    name="branch"
                    id="branch-select"
                    onChange={handleSelectChanges}
                  >
                    <option>Select Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch.value}>
                        {branch.label}
                      </option>
                    ))}
                  </AvField>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-between mb-3 mt-2">
                  <label>Category</label>
                  <div className="button-section">
                    <button
                      className="btn btn-success p-1"
                      type="button"
                      onClick={() => addFormFields()}
                    >
                      <i className="fa fa-plus p-1"></i>
                    </button>
                  </div>
                </div>
                {loader !== "" ? (
                  loader
                ) : (
                  <>
                    {cateName.formValues.map((element, index) => (
                      <div
                        className="mb-3 d-flex justify-content-between"
                        key={index}
                      >
                        <div style={{ width: "calc(100% - 40px)" }}>
                          <AvField
                            type="text"
                            name="name"
                            placeholder="Name"
                            onChange={e => handleChange(index, e)}
                            value={element.name || ""}
                          />
                        </div>
                        {index ? (
                          <span
                            className="btn btn-danger"
                            onClick={() => removeFormFields(index)}
                          >
                            &times;
                          </span>
                        ) : null}
                      </div>
                    ))}
                  </>
                )}
                <div className="mb-3">
                  <label htmlFor="branch-select" className="form-label">
                    Branch
                  </label>
                  <Select
                    isMulti
                    options={branches}
                    onChange={handleSelectChange}
                    placeholder="Select Branches"
                  />
                </div>
              </>
            )}
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_standard(false)}
                  >
                    {props.category !== undefined ? "Submit" : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
