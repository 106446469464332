import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import InputMask from "react-input-mask"
import ToggleSwitch from "../../Toggle-Switch"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { map } from "lodash"
import Select from "react-select"

export default function StaffModal(props) {
  const [loading, setLoading] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [error, setError] = useState("")
  const [allRoles, setAllRoles] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [ips, setIps] = useState({ form: [{ ip: "" }] })
  const [timing, setTiming] = useState({
    form2: [{ day: "", start_time: "", end_time: "" }],
  })
  const [passField1, setPassField1] = useState(false)
  const [passField2, setPassField2] = useState(false)
  const [selectedRole, setSelectedRole] = useState("")
  const [showBranchField, setShowBranchField] = useState(false)
  const [branch, setBranch] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])

  const toggle = () => {
    setPassField1(!passField1)
  }

  const toggle2 = () => {
    setPassField2(!passField2)
  }

  const handleIps = (i, e) => {
    let form = ips.form
    form[i][e.target.name] = e.target.value
    setIps({ form })
  }

  const addIpFields = () => {
    setIps({
      form: [...ips.form, { ip: "" }],
    })
  }

  const removeIpFields = i => {
    let form = ips.form
    form.splice(i, 1)
    setIps({ form })
  }

  const handleTiming = (i, e) => {
    let form2 = timing.form2
    form2[i][e.target.name] = e.target.value
    setTiming({ form2 })
  }

  const addTimingFields = () => {
    setTiming({
      form2: [...timing.form2, { day: "", start_time: "", end_time: "" }],
    })
  }

  const removeTimingFields = i => {
    let form2 = timing.form2
    form2.splice(i, 1)
    setTiming({ form2 })
  }

  const getSelectedRole = e => {
    setSelectedRole(e)
    if (e === "Branch Manager") {
      setShowBranchField(true)
    } else {
      setShowBranchField(false)
    }

    if (e === "Team Leader") {
      setAdmin(true)
    }

    if (e === "Sub Administrator") {
      getAllUser("sub")
    }
  }

  const getAllBranch = () => {
    setBranch([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/branch`,
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(res => {
        setBranch(res.data.data)
      })
      .catch(error => {
        console.error("Error fetching branches:", error)
      })
  }

  useEffect(() => {
    getAllBranch()
  }, [])

  const updateUser = e => {
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/restrict-user`,
        {
          id: props.user.id,
          restriction: isActive,
          restriction_ip: props.user.restriction_ip,
          restriction_timing: props.user.restriction_timing,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const updateUser2 = e => {
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/restrict-user`,
        {
          id: props.user.id,
          restriction: props.user.restriction,
          restriction_ip: isActive,
          restriction_timing: props.user.restriction_timing,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const updateUser3 = e => {
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/restrict-user`,
        {
          id: props.user.id,
          restriction: props.user.restriction,
          restriction_ip: props.user.restriction_ip,
          restriction_timing: isActive,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const getAllRoles = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/role?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setAllRoles(res.data.data.data)
      })
  }

  const getAllUser = e => {
    let role = "1,2,4"
    if (e == "sub") {
      role = "1,4"
    } else {
      role = "1,2,4"
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user?role_id=${role}&per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setError(res.data.message)
        setAllUsers(res.data.data.data)
      })
  }

  const submitUser = (e, value) => {
    setLoading(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    if (props.user !== undefined) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/user/${props.user.id}`,
          {
            name: e.target.name.value,
            email: e.target.email.value,
            password: e.target.password.value,
            confirm_password: e.target.confirm_password.value,
            phone: e.target.phone.value,
            role:
              e.target.role !== null ? e.target.role.value : "Administrator",
            team_leader_id:
              e.target.team_leader_id !== undefined
                ? e.target.team_leader_id.value
                : "",
            status: e.target.status !== undefined ? e.target.status.value : "1",
            dob: e.target.dob !== undefined ? e.target.dob.value : "",
            anniversary:
              e.target.anniversary !== undefined
                ? e.target.anniversary.value
                : "",
            monthly_target:
              e.target.monthly_target !== undefined
                ? e.target.monthly_target.value
                : "",
            ip_security: ips.form,
            whatsapp_username: e.target.whatsapp_username.value,
            whatsapp_token: e.target.whatsapp_token.value,
            assignees: selectedBranches,
            // time_security: timing.form2,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setIsOpen(false)
            props.setmodal_standard(false)
            props.setUser(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/user`,
          {
            name: e.target.name.value,
            email: e.target.email.value,
            password: e.target.password.value,
            confirm_password: e.target.confirm_password.value,
            phone: e.target.phone.value,
            role: e.target.role.value,
            team_leader_id: e.target.team_leader_id.value,
            dob: e.target.dob !== undefined ? e.target.dob.value : "",
            anniversary:
              e.target.anniversary !== undefined
                ? e.target.anniversary.value
                : "",
            monthly_target: e.target.monthly_target.value,
            status: e.target.status !== undefined ? e.target.status.value : "1",
            whatsapp_username: e.target.whatsapp_username.value,
            whatsapp_token: e.target.whatsapp_token.value,
            assignees: selectedBranches,
          },
          {
            headers: {
              Authorization: `Bearer ${
                getCurrentUser().token == null ? "" : getCurrentUser().token
              }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllUsers()
            props.setmodal_standard(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_standard(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    getAllRoles()
    getAllUser("all")
    if (props.user !== undefined) {
      setIps({
        form: props.user.user_ips?.map(item => ({ ip: item.ip })),
      })
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={props.modal_standard}
        centered={true}
        size={
          props.user && props.user.role_name === "Administrator" ? "" : "xl"
        }
      >
        <AvForm
          className="mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => submitUser(e, v)}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.user !== undefined ? "Edit User" : "Create User"}
            </h5>
            <button
              className="cross__btn"
              onClick={() => props.setmodal_standard(false)}
              aria-hidden="true"
              type="button"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col
                md={
                  props.user !== undefined
                    ? props.user.role_name === "Administrator"
                      ? "12"
                      : "5"
                    : "6"
                }
              >
                <div className="mb-3 required">
                  <AvField
                    label="Name"
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    value={props.user && props.user.name}
                  />
                </div>
                <div className="mb-3 required">
                  <AvField
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    value={props.user && props.user.email}
                  />
                </div>
                <div className="mb-3 required">
                  <AvField
                    label="Password"
                    type={passField1 ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    value={props.user && props.user.password}
                  />
                  {passField1 ? (
                    <span
                      className="float-end"
                      style={{ marginTop: "-27px", marginRight: "10px" }}
                    >
                      <i
                        className="fa fa-eye-slash"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggle()}
                      ></i>
                    </span>
                  ) : (
                    <span
                      className="float-end"
                      style={{ marginTop: "-27px", marginRight: "10px" }}
                    >
                      <i
                        className="fa fa-eye"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggle()}
                      ></i>
                    </span>
                  )}
                </div>
                <div className="mb-3 required">
                  <AvField
                    label="Confirm Password"
                    type={passField2 ? "text" : "password"}
                    name="confirm_password"
                    placeholder="Confirm password"
                    value={props.user && props.user.confirm_password}
                  />
                  {passField2 ? (
                    <span
                      className="float-end"
                      style={{ marginTop: "-27px", marginRight: "10px" }}
                    >
                      <i
                        className="fa fa-eye-slash"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggle2()}
                      ></i>
                    </span>
                  ) : (
                    <span
                      className="float-end"
                      style={{ marginTop: "-27px", marginRight: "10px" }}
                    >
                      <i
                        className="fa fa-eye"
                        style={{ cursor: "pointer" }}
                        onClick={() => toggle2()}
                      ></i>
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <AvField
                    label="Phone/Whatsapp Number"
                    type="number"
                    onFocus={e =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault()
                        },
                        { passive: false }
                      )
                    }
                    name="phone"
                    placeholder="Enter number"
                    value={props.user && props.user.phone}
                  />
                </div>
                {props.user !== undefined ? (
                  <>
                    {props.user && props.user.role_name === "Administrator" ? (
                      ""
                    ) : (
                      <>
                        <div className="mb-3 required">
                          <div className="form-group mb-3">
                            <AvField
                              className="select-arrow"
                              type="select"
                              name="role"
                              label="Role"
                              value={props.user && props.user.role_name}
                              onChange={e => getSelectedRole(e.target.value)}
                            >
                              <option value="" hidden>
                                Select Role
                              </option>
                              {allRoles &&
                                allRoles.map((item, i) =>
                                  item.name !== "Administrator" ? (
                                    getCurrentUser()
                                      ?.enable_accountant_login !== 1 ? (
                                      item.name !== "Accountant" ? (
                                        <option value={item.name} key={i}>
                                          {item.name}
                                        </option>
                                      ) : (
                                        ""
                                      )
                                    ) : getCurrentUser()
                                        ?.enable_warehouse_login !== 1 ? (
                                      item.name !== "Warehouse" ? (
                                        <option value={item.name} key={i}>
                                          {item.name}
                                        </option>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <option value={item.name} key={i}>
                                        {item.name}
                                      </option>
                                    )
                                  ) : (
                                    ""
                                  )
                                )}
                            </AvField>
                          </div>

                          <div className="form-group mb-3">
                            <label htmlFor="branch" className="form-label">
                              Branch
                            </label>
                            <Select
                              id="branch"
                              isMulti
                              className="select-arrow"
                              placeholder="Select Branch"
                              name="branch"
                              options={branch.map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))}
                              onChange={selectedOptions => {
                                const selectedIds = selectedOptions.map(
                                  option => option.value
                                )
                                setSelectedBranches(selectedIds)
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-3 required">
                          <div className="form-group mb-3">
                            <AvField
                              className="select-arrow"
                              type="select"
                              name="team_leader_id"
                              label="Senior"
                              value={
                                admin
                                  ? 1
                                  : props.user && props.user.team_leader_id
                              }
                            >
                              <option value="" hidden>
                                Select Senior
                              </option>
                              {allUsers &&
                                allUsers.map((item, i) => (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                ))}
                            </AvField>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="mb-3 required">
                      <div className="form-group mb-3">
                        <AvField
                          className="select-arrow"
                          type="select"
                          name="role"
                          label="Role"
                          value={props.user && props.user.role_name}
                          onChange={e => getSelectedRole(e.target.value)}
                        >
                          <option value="" hidden>
                            Select Role
                          </option>
                          {allRoles &&
                            allRoles.map((item, i) =>
                              item.name !== "Administrator" ? (
                                getCurrentUser()?.enable_accountant_login !==
                                1 ? (
                                  item.name !== "Accountant" ? (
                                    <option value={item.name} key={i}>
                                      {item.name}
                                    </option>
                                  ) : (
                                    ""
                                  )
                                ) : getCurrentUser()?.enable_warehouse_login !==
                                  1 ? (
                                  item.name !== "Warehouse" ? (
                                    <option value={item.name} key={i}>
                                      {item.name}
                                    </option>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  <option value={item.name} key={i}>
                                    {item.name}
                                  </option>
                                )
                              ) : (
                                ""
                              )
                            )}
                        </AvField>
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="branch" className="form-label">
                          Branch
                        </label>
                        <Select
                          id="branch"
                          isMulti
                          className="select-arrow"
                          placeholder="Select Branch"
                          name="branch"
                          options={branch.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))}
                          onChange={selectedOptions => {
                            const selectedIds = selectedOptions.map(
                              option => option.value
                            )
                            setSelectedBranches(selectedIds)
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3 required">
                      <div className="form-group mb-3">
                        <AvField
                          className="select-arrow"
                          type="select"
                          name="team_leader_id"
                          label="Senior"
                          value={
                            admin ? 1 : props.user && props.user.team_leader_id
                          }
                        >
                          <option value="" hidden>
                            Select Senior
                          </option>
                          {allUsers &&
                            allUsers.map((item, i) => (
                              <option value={item.id} key={i}>
                                {item.name}
                              </option>
                            ))}
                        </AvField>
                      </div>
                    </div>
                  </>
                )}
                <div className="mb-3">
                  <AvField
                    className="select-arrow"
                    type="select"
                    name="status"
                    label="Status"
                    value={props.user && props.user.status}
                  >
                    <option value="1">Active</option>
                    <option value="0">Blocked</option>
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    label="Instance ID"
                    type="text"
                    name="whatsapp_username"
                    placeholder="Enter your username"
                    value={props.user && props.user.whatsapp_username}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    label="Token"
                    type="text"
                    name="whatsapp_token"
                    placeholder="Enter your token"
                    value={props.user && props.user.whatsapp_token}
                  />
                </div>
              </Col>
              <Col
                md={
                  props.user !== undefined
                    ? props.user.role_name === "Administrator"
                      ? "0"
                      : "7"
                    : "6"
                }
              >
                {props.user !== undefined ? (
                  <>
                    {props.user && props.user.role_name === "Administrator" ? (
                      ""
                    ) : (
                      <div className="mb-3">
                        <AvField
                          label="Monthly Target"
                          type="text"
                          name="monthly_target"
                          placeholder="Enter monthly target"
                          value={props.user && props.user.monthly_target}
                        />
                      </div>
                    )}
                    {props.user && props.user.role_name === "Administrator" ? (
                      ""
                    ) : (
                      <>
                        <div className="mb-3">
                          <label className="me-3">Restriction</label>
                          <ToggleSwitch
                            id={`${props.user.id}-restriction`}
                            name="restriction"
                            checked={
                              props.user.restriction === 1 ? true : false
                            }
                            onChange={e => updateUser(e)}
                            value={props.user.restriction}
                          />
                        </div>

                        <div className="d-flex justify-content-between mb-3 mt-2">
                          <label>IP Address</label>
                          <div className="button-section">
                            <button
                              className="btn btn-success p-1"
                              type="button"
                              onClick={() => addIpFields()}
                            >
                              <i className="fa fa-plus p-1"></i>
                            </button>
                          </div>
                        </div>
                        {ips.form?.map((element, index) => (
                          <div
                            className="mb-3 d-flex justify-content-between"
                            key={index}
                          >
                            <div style={{ width: "calc(100% - 40px)" }}>
                              <AvField
                                name="ip"
                                placeholder="IP Address"
                                onChange={e => handleIps(index, e)}
                                value={element.ip}
                              />
                              {/* <InputMask
                                          mask="999.999.999.999"
                                          name="ip"
                                          placeholder="IP Address"
                                          onChange={(e) => handleIps(index, e)}
                                          className="form-control input-color"
                                       ></InputMask> */}
                            </div>
                            {index ? (
                              <span
                                className="btn btn-danger"
                                onClick={() => removeIpFields(index)}
                              >
                                &times;
                              </span>
                            ) : null}
                          </div>
                        ))}
                      </>
                    )}
                    {props.user && props.user.role_name === "Administrator" ? (
                      ""
                    ) : (
                      <div className="mb-3">
                        <label className="me-3">Activate IP</label>
                        {props.user.restriction === 1 ? (
                          <ToggleSwitch
                            id={`${props.user.id}-restriction_ip`}
                            name="restriction_ip"
                            checked={
                              props.user.restriction_ip === 1 ? true : false
                            }
                            onChange={e => updateUser2(e)}
                            value={props.user.restriction_ip}
                          />
                        ) : (
                          <ToggleSwitch
                            id={`${props.user.id}-restriction_ip`}
                            name="restriction_ip"
                            disabled
                            checked={
                              props.user.restriction_ip === 1 ? true : false
                            }
                            onChange={e => updateUser2(e)}
                            value={props.user.restriction_ip}
                          />
                        )}
                      </div>
                    )}
                    {/* <div className="d-flex justify-content-between mb-3 mt-2">
                                 <label>Allowed Days & Time</label>
                                 <div className="button-section">
                                    <button
                                       className="btn btn-success p-1"
                                       type="button"
                                       onClick={() => addTimingFields()}
                                    >
                                       <i className="fa fa-plus p-1"></i>
                                    </button>
                                 </div>
                              </div> */}
                    {/* {timing.form2.map((element, index) => (
                                 <div className="mb-3 d-flex justify-content-between" key={index}>
                                    <div style={{ width: 'calc(100% - 40px)' }}>
                                       <div className="row">
                                          <div className="col">
                                             <AvField
                                                onChange={(e) => handleTiming(index, e)}
                                                className="select-arrow"
                                                type="select"
                                                name="day"
                                             >
                                                <option value="" hidden>
                                                   Select day
                                                </option>
                                                <option value="1">Monday</option>
                                                <option value="2">Tuesday</option>
                                                <option value="3">Wednesday</option>
                                                <option value="4">Thrusday</option>
                                                <option value="5">Friday</option>
                                                <option value="6">Saturday</option>
                                                <option value="7">Sunday</option>
                                             </AvField>
                                          </div>
                                          <div className="col">
                                             <AvField
                                                onChange={(e) => handleTiming(index, e)}
                                                type="time"
                                                name="start_time"
                                             />
                                          </div>
                                          <div className="col">
                                             <AvField
                                                onChange={(e) => handleTiming(index, e)}
                                                type="time"
                                                name="end_time"
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    {index ? (
                                       <span
                                          className="btn btn-danger"
                                          onClick={() => removeTimingFields(index)}
                                       >
                                          &times;
                                       </span>
                                    ) : null}
                                 </div>
                              ))} */}
                    {/* <div className="mb-3">
                                 <label className="me-3">
                                    Activate Timing
                                 </label>
                                 {props.user.restriction === 1 ?
                                    <ToggleSwitch
                                       id={`${props.user.id}-restriction_timing`}
                                       name="restriction_timing"
                                       checked={props.user.restriction_timing === 1 ? true : false}
                                       onChange={(e) => updateUser3(e)}
                                       value={props.user.restriction_timing}
                                    />
                                    :
                                    <ToggleSwitch
                                       id={`${props.user.id}-restriction_timing`}
                                       name="restriction_timing"
                                       disabled
                                       checked={props.user.restriction_timing === 1 ? true : false}
                                       onChange={(e) => updateUser3(e)}
                                       value={props.user.restriction_timing}
                                    />
                                 }
                              </div> */}
                  </>
                ) : (
                  <>
                    <div className="mb-3">
                      <AvField
                        label="Monthly Target"
                        type="text"
                        name="monthly_target"
                        placeholder="Enter monthly target"
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmodal_standard(false)}
                  >
                    {props.user !== undefined ? "Submit" : "Create"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
