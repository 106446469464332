import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import SubCateList from "./SubCateList"
import SubCateModal from "./SubCateModal"
import InputFilter from "../../Filter/SearchFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const SubCateTable = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [id_filter, setid_filter] = useState(false)
  const [name_filter, setname_filter] = useState(false)
  const [sort, setSort] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [sort3, setSort3] = useState(false)
  const [sort4, setSort4] = useState(false)
  const [sort5, setSort5] = useState(false)
  const [sort6, setSort6] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [category, setCategory] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [blur, setBlur] = useState(false)
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  const getSubCategory = () => {
    setLoader(<Loader />)
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/sub-category?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }&${branchQuery}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setCategory(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "id") {
      localStorage.setItem("leadID", e)
    }
    if (type == "name") {
      localStorage.setItem("leadName", e)
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e)
    }
    setSearchedCount("")
    setCategory([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/sub-category?id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
         &sub_category=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setCategory(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setid_filter(false)
          setname_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setCategory([])
    setAllPages([])
    setCounter("")
    setLoader(<Loader />)
    axios
      .get(
        `${e}&id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
            &sub_category=${
              localStorage.getItem("leadName") !== null
                ? localStorage.getItem("leadName")
                : ""
            }
            &sort=${
              localStorage.getItem("leadSort") !== null
                ? localStorage.getItem("leadSort")
                : ""
            }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setCategory(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const selectAllCheck = check => {
    if (check.checked) {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true
          leadsSelectedIds.push(parseInt(ele[i].value))
        }
      }
    } else {
      var ele = document.getElementsByName("chk")
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = false
          var array = leadsSelectedIds
          var index = array.indexOf(parseInt(ele[i].value))
          if (index !== -1) {
            array.splice(index, 1)
          }
        }
      }
    }
    if (leadsSelectedIds.length === 0) {
      setBlur(false)
    } else {
      setBlur(true)
    }
  }

  const exportLead = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/subcategory-export`,
        {
          ids: leadsSelectedIds,
        },

        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-subcategory-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const deleteLeads = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-subcategory-deleted`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadsSelectedIds([])
          getSubCategory()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  useEffect(() => {
    getSubCategory()
  }, [])

  return (
    <>
      {modal_standard ? (
        <SubCateModal
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          getSubCategory={getSubCategory}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <span className="text-danger">You won't be able to revert this!</span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Sub-Categories</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {getPermission().sub_category.sub_category.delete.is_checked ===
              "yes" ? (
                <>
                  {blur ? (
                    <button
                      className="btn btn-danger me-2"
                      onClick={() => {
                        setconfirm_alert(true)
                      }}
                    >
                      Multiple Delete
                    </button>
                  ) : (
                    <button className="btn btn-danger me-2" disabled>
                      Multiple Delete
                    </button>
                  )}
                </>
              ) : (
                ""
              )}
              {getPermission() &&
              getPermission().lead_export.lead_export.view.is_checked ===
                "yes" ? (
                <button
                  className="btn btn-primary submit__button me-2"
                  onClick={() => {
                    exportLead()
                  }}
                >
                  Export
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_standard(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Data
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr className="static-header">
                  <th>
                    <input
                      onClick={e => selectAllCheck(e.target)}
                      className="p-0 d-inline-block"
                      type="checkbox"
                    />
                  </th>
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      ID
                      {id_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setid_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadID") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort5 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort5(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort6 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort5(true),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="first-filter">
                        <InputFilter
                          id_filter={id_filter}
                          setid_filter={setid_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Name
                      {name_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setname_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadName") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setname_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("sub_category,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort2(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("sub_category,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(616%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("sub_category,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort2(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("sub_category,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(616%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort2 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort(true),
                                setSort3(false),
                                setSort4(false),
                                setSort5(false),
                                setSort6(false),
                                getSearchedLeads("sub_category,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(616%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <InputFilter
                        name_filter={name_filter}
                        setname_filter={setname_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Category</th>
                  <th>Branch</th>
                  <th>Action</th>
                </tr>
                {category &&
                  category.map((item, obj) => (
                    <SubCateList
                      key={obj}
                      data={item}
                      i={obj}
                      setBlur={setBlur}
                      setLeadsSelectedIds={setLeadsSelectedIds}
                      leadsSelectedIds={leadsSelectedIds}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getSubCategory}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default SubCateTable
