import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../Pagination"
import Loader from "../Loader/loader.js"
import ComplaintList from "./complaintList"
import ComplaintModal from "./complaintModal"
import MultipleChoice from "../Filter/MultipleChoice"
import InputFilter from "../Filter/SearchFilter"

const ComplaintTable = () => {
  const [modal_complaint, setmodal_complaint] = useState(false)
  const [id_filter, setid_filter] = useState(false)
  const [status3_filter, setstatus3_filter] = useState(false)
  const [sort, setSort] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [sort3, setSort3] = useState(false)
  const [sort4, setSort4] = useState(false)
  const [sort5, setSort5] = useState(false)
  const [sort6, setSort6] = useState(false)
  const [complaint, setComplaint] = useState([])
  const [error, setError] = useState("")
  const [counter, setCounter] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [searchedCount, setSearchedCount] = useState("")
  const [branch, setBranch] = useState(
    localStorage.getItem("selectedBranch")
      ? localStorage.getItem("selectedBranch")
      : ""
  )

  const getAllComplaints = () => {
    setComplaint([])
    setLoader(<Loader />)
    const branchQuery = branch !== "" ? `&branch_id=${branch}` : ""
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/complaint?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }&${branchQuery}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setComplaint(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const getSearchedLeads = (e, type) => {
    setError("")
    if (type == "id") {
      localStorage.setItem("leadID", e)
    }
    if (type == "status3") {
      localStorage.setItem("leadStatus3", e)
    }
    if (type == "sort") {
      localStorage.setItem("leadSort", e)
    }
    setSearchedCount("")
    setComplaint([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/complaint?id=${
          localStorage.getItem("leadID") !== null
            ? localStorage.getItem("leadID")
            : ""
        }
         &resolved=${
           localStorage.getItem("leadStatus3") !== null
             ? localStorage.getItem("leadStatus3")
             : ""
         }
         &sort=${
           localStorage.getItem("leadSort") !== null
             ? localStorage.getItem("leadSort")
             : ""
         }
         &per_page=${
           localStorage.getItem("PerPage") !== null
             ? localStorage.getItem("PerPage")
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setComplaint(res.data.data.data)
          setAllPages(res.data.data)
          setSearchedCount(res.data.data.total)
          setid_filter(false)
          setstatus3_filter(false)
        } else {
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setComplaint([])
    setCounter("")
    setAllPages([])
    setLoader(<Loader />)
    axios
      .get(`${e}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setComplaint(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  useEffect(() => {
    getAllComplaints()
  }, [])

  return (
    <>
      <ComplaintModal
        modal_complaint={modal_complaint}
        setmodal_complaint={setmodal_complaint}
        getAllComplaints={getAllComplaints}
      />
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Complaints</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_complaint(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col>
            {searchedCount !== "" ? (
              <h6 className="">
                Searched Data
                <span> ({searchedCount})</span>
              </h6>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <thead>
                <tr className="static-header">
                  <th>#</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      ID
                      {id_filter ? (
                        <>
                          <span
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setid_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadID") == "" ? (
                            <span
                              className=""
                              style={{ cursor: "pointer" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              className=""
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setid_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      {sort5 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort5(true),
                                setSort2(false),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,asc", "sort")
                            }}
                          >
                            <i className="fa fa-sort-up ms-2"></i>
                          </span>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSort6(true),
                                setSort(false),
                                setSort3(false),
                                setSort4(false),
                                setSort2(false),
                                setSort5(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      )}
                      {sort6 ? (
                        <>
                          <span
                            className="fs-5"
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => {
                              setSort2(false),
                                setSort5(true),
                                setSort3(false),
                                setSort4(false),
                                setSort(false),
                                setSort6(false),
                                getSearchedLeads("id,desc", "sort")
                            }}
                          >
                            <i
                              className="fa fa-sort-down"
                              style={{
                                position: "absolute",
                                left: "0",
                                bottom: "0",
                                transform: "translate(395%, -3px)",
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="first-filter">
                        <InputFilter
                          id_filter={id_filter}
                          setid_filter={setid_filter}
                          getSearchedLeads={getSearchedLeads}
                        />
                      </div>
                    </div>
                  </th>
                  <th>Customer</th>
                  <th>Complaint</th>
                  <th>
                    <div
                      className="position-relative"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Resolved
                      {status3_filter ? (
                        <>
                          <span
                            className=""
                            style={{ cursor: "pointer", color: "orange" }}
                            onClick={() => setstatus3_filter(false)}
                          >
                            <i className="fa fa-times fa-lg ms-2"></i>
                          </span>
                        </>
                      ) : (
                        <>
                          {localStorage.getItem("leadStatus3") == "" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => setstatus3_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "orange" }}
                              onClick={() => setstatus3_filter(true)}
                            >
                              <i className="fa fa-filter ms-2"></i>
                            </span>
                          )}
                        </>
                      )}
                      <MultipleChoice
                        status3_filter={status3_filter}
                        setstatus3_filter={setstatus3_filter}
                        getSearchedLeads={getSearchedLeads}
                      />
                    </div>
                  </th>
                  <th>Branch</th>
                  <th>Created at</th>
                  <th>Updated at</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {complaint &&
                  complaint.map((item, obj) => (
                    <ComplaintList
                      key={obj}
                      data={item}
                      i={obj}
                      getAllComplaints={getAllComplaints}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllComplaints}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default ComplaintTable
